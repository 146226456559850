// === NPM
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Stack } from "@mui/material";
// === LOCAL
import { ReactComponent as Billing } from "@/assets/icons/home/billing.svg";
import { ReactComponent as Delivery } from "@/assets/icons/home/delivery.svg";
import { ReactComponent as Dpe } from "@/assets/icons/home/dpe.svg";
import { ReactComponent as Farm } from "@/assets/icons/home/farm.svg";
import { ReactComponent as HealthReporting } from "@/assets/icons/home/health_reporting.svg";
import { ReactComponent as Horse } from "@/assets/icons/home/horse.svg";
import { ReactComponent as Key } from "@/assets/icons/home/key.svg";
import { ReactComponent as MonitoringIntervention } from "@/assets/icons/home/list.svg";
import { ReactComponent as Notification } from "@/assets/icons/home/notification.svg";
import { ReactComponent as Referential } from "@/assets/icons/home/referential.svg";
import { ReactComponent as Training } from "@/assets/icons/home/training.svg";
import { ReactComponent as User } from "@/assets/icons/home/user.svg";
import { ReactComponent as VaccinationIntervention } from "@/assets/icons/home/vaccination_intervention.svg";
import { ReactComponent as VaccinationSite } from "@/assets/icons/home/vaccination_site.svg";
import { ReactComponent as Vaccine } from "@/assets/icons/home/vaccine_order.svg";
import { moduleColors } from "@/resources/CssConstant";
import { FeatureFlags, hasFeatureFlag } from "@/resources/hasFeatureFlag";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { routerLinks } from "@/routers/RouterConstant";
import PermissionsCheck from "../generics/PermissionsCheck";
import HomeFamDamTour from "../HomeTour/HomeFamDamTour";
import HomeVetTour from "../HomeTour/HomeVetTour";
import AlertBanners from "../UserNotifications/containers/AlertBanners";
import Activities from "./containers/Activities";
import EditHomeModules from "./containers/EditHomeModules";
import { HomeModules } from "./containers/HomeModules";
import { IModule } from "./interface";

export default function Home() {
    const [updateModules, setUpdateModules] = useState<boolean>(false);

    const modules: IModule[] = [
        //Training
        {
            name: "Mes formations",
            permissions: [UserSituation.REGISTERED_IN_PRACTICE],
            path: routerLinks.continuousTraining.trainings(),
            icon: <Training />,
            background: moduleColors.purple,
            key: "continuous_training",
        },
        //Delivery
        {
            name: "Déclarer mes cessions",
            permissions: [UserSituation.FAM_DAM_ADMIN, UserSituation.FAM_DAM_USER],
            path: routerLinks.delivery.dashboard.trackingLogs(),
            icon: <Delivery />,
            background: moduleColors.blue,
            key: "delivery_tracking_logs_fam_dam",
        },
        {
            name: "Déclarer mes cessions",
            permissions: [
                UserSituation.PHARMACIST,
                UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                UserSituation.REGISTERED_IN_PRACTICE,
                UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
            ],
            path: routerLinks.delivery.manualDelivery.form(),
            icon: <Delivery />,
            background: moduleColors.blue,
            key: "delivery_manual_delivery",
        },
        {
            name: "Consulter mes données de cession",
            permissions: [
                UserSituation.ADMIN_CALYPSO,
                UserSituation.ANMV_MED,
                UserSituation.FAM_DAM_ADMIN,
                UserSituation.FAM_DAM_USER,
                UserSituation.PHARMACIST,
                UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                UserSituation.REGISTERED_IN_PRACTICE,
                UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
            ],
            path: routerLinks.delivery.dashboard.trackingLogs(),
            icon: <Delivery />,
            background: moduleColors.blue,
            key: "tracking_logs",
        },
        //IAHP
        {
            name: "Mes élevages",
            permissions: [
                UserSituation.ADMIN_CALYPSO,
                UserSituation.ADMIN_DDPP,
                UserSituation.ADMIN_DGAL,
                UserSituation.ADMIN_DRAAF,
                UserSituation.USER_DDPP,
                UserSituation.USER_DGAL,
                UserSituation.USER_DRAAF,
                UserSituation.REGISTERED_IN_PRACTICE,
            ],
            preferences: [Preference.IAHP],
            path: routerLinks.iahp.farm.view(),
            icon: <Farm />,
            background: moduleColors.orange,
            key: "farm",
        },
        {
            name: "Mes commandes de vaccins",
            permissions: [
                UserSituation.ADMIN_CALYPSO,
                UserSituation.ADMIN_DDPP,
                UserSituation.ADMIN_DGAL,
                UserSituation.ADMIN_DRAAF,
                UserSituation.USER_DDPP,
                UserSituation.USER_DGAL,
                UserSituation.USER_DRAAF,
                UserSituation.REGISTERED_IN_PRACTICE,
            ],
            preferences: [Preference.IAHP],
            path: routerLinks.vaccinationCampaign.vaccine.view(),
            icon: <Vaccine />,
            background: moduleColors.orange,
            key: "vaccine_order",
        },
        {
            name: "Mes interventions de vaccination",
            permissions: [
                UserSituation.ADMIN_CALYPSO,
                UserSituation.ADMIN_DDPP,
                UserSituation.ADMIN_DGAL,
                UserSituation.ADMIN_DRAAF,
                UserSituation.USER_DDPP,
                UserSituation.USER_DGAL,
                UserSituation.USER_DRAAF,
                UserSituation.REGISTERED_IN_PRACTICE,
            ],
            preferences: [Preference.IAHP],
            path: routerLinks.iahp.vaccinationSite.vaccinationIntervention.base(),
            icon: <VaccinationIntervention />,
            background: moduleColors.orange,
            key: "vaccination_intervention",
        },
        {
            name: "Mes chantiers de vaccination",
            permissions: [
                UserSituation.ADMIN_CALYPSO,
                UserSituation.ADMIN_DDPP,
                UserSituation.ADMIN_DGAL,
                UserSituation.ADMIN_DRAAF,
                UserSituation.USER_DDPP,
                UserSituation.USER_DGAL,
                UserSituation.USER_DRAAF,
                UserSituation.REGISTERED_IN_PRACTICE,
            ],
            preferences: [Preference.IAHP],
            path: routerLinks.iahp.vaccinationSite.view(),
            icon: <VaccinationSite />,
            background: moduleColors.orange,
            key: "vaccination_site",
        },

        {
            name: "Mes interventions de surveillance",
            permissions: [
                UserSituation.ADMIN_CALYPSO,
                UserSituation.ADMIN_DDPP,
                UserSituation.ADMIN_DGAL,
                UserSituation.ADMIN_DRAAF,
                UserSituation.USER_DDPP,
                UserSituation.USER_DGAL,
                UserSituation.USER_DRAAF,
                UserSituation.REGISTERED_IN_PRACTICE,
            ],
            preferences: [Preference.IAHP],
            path: routerLinks.iahp.monitoringIntervention.view(),
            icon: <MonitoringIntervention />,
            background: moduleColors.orange,
            key: "monitoring_intervention",
        },
        //BUTCHERY SECTOR
        {
            name: "Suivi exclusion filière bouchère",
            permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE],
            preferences: [Preference.BUTCHERY_SECTOR],
            path: routerLinks.sanitaryFollowUp.butcherySector.exclusion(),
            background: moduleColors.orange,
            icon: <Horse />,
            key: "exclusion",
        },
        //HEALTH REPORTING
        ...(hasFeatureFlag(FeatureFlags.HEALTH_REPORTING)
            ? [
                  {
                      name: "Mes signalements de maltraitance",
                      background: moduleColors.blue,

                      permissions: [
                          UserSituation.ADMIN_CALYPSO,
                          UserSituation.REGISTERED_IN_PRACTICE,
                          UserSituation.ADMIN_DDPP,
                          UserSituation.ADMIN_DGAL,
                          UserSituation.ADMIN_DRAAF,
                          UserSituation.USER_DDPP,
                          UserSituation.USER_DGAL,
                          UserSituation.USER_DRAAF,
                      ],
                      path: routerLinks.healthReporting.animalAbuse.view(),
                      icon: <HealthReporting />,
                      key: "health_reporting",
                  },
              ]
            : []),
        //HEALTH REPORTING
        ...(hasFeatureFlag(FeatureFlags.BILLING)
            ? [
                  {
                      name: "Facturation IAHP",
                      background: moduleColors.orange,
                      permissions: [
                          UserSituation.ADMIN_CALYPSO,
                          UserSituation.REGISTERED_IN_PRACTICE,
                          UserSituation.ADMIN_DDPP,
                          UserSituation.USER_DDPP,
                      ],
                      path: routerLinks.iahp.billing.vaccinationSites(),
                      preferences: [Preference.IAHP],
                      icon: <Billing />,
                      key: "billing",
                  },
              ]
            : []),
        //Administration
        {
            name: "Consulter la liste des DPE",
            permissions: [
                UserSituation.ADMIN_DDPP,
                UserSituation.ADMIN_DGAL,
                UserSituation.ADMIN_DRAAF,
                UserSituation.USER_DDPP,
                UserSituation.USER_DGAL,
                UserSituation.USER_DRAAF,
                UserSituation.ANMV_MED,
                UserSituation.FAM_DAM_ADMIN,
                UserSituation.FAM_DAM_USER,
            ],
            path: routerLinks.referential.global.dpeDirectory(),
            icon: <Dpe />,
            background: moduleColors.green,
            key: "dpe_directory",
        },
        {
            name: "Gestion des utilisateurs",
            permissions: [UserSituation.FAM_DAM_ADMIN],
            path: routerLinks.admin.users.famDam(),
            icon: <User />,
            background: moduleColors.green,
            key: "fam_dam_users",
        },
        {
            name: "Gestion des utilisateurs",
            permissions: [UserSituation.ADMIN_DDPP, UserSituation.ADMIN_DGAL, UserSituation.ADMIN_DRAAF],
            path: routerLinks.admin.users.ma(),
            icon: <User />,
            background: moduleColors.green,
            key: "ma_users",
        },
        {
            name: "Gestion des utilisateurs",
            permissions: [UserSituation.ADMIN_CALYPSO],
            path: routerLinks.admin.users.global(),
            icon: <User />,
            background: moduleColors.green,
            key: "global_users",
        },
        {
            name: "Consulter la liste des médicaments",
            permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED],
            path: routerLinks.referential.delivery.drugs(),
            icon: <Delivery />,
            background: moduleColors.green,
            key: "referential_drugs",
        },
        //API KEYS
        {
            name: "Gestion des clefs d'API",
            permissions: [UserSituation.ADMIN_CALYPSO],
            path: routerLinks.admin.apiKey.alerts(),
            background: moduleColors.red,
            icon: <Key />,
            key: "api_keys_alerts",
        },
        //NOTIFICATIONS
        {
            name: "Gestion des notifications",
            permissions: [UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_NOTIF],
            path: routerLinks.admin.notifications.gestion(),
            background: moduleColors.yellow,
            icon: <Notification />,
            key: "notifications",
        },
        //REFERENTIAL
        {
            name: "Gestion des référentiels",
            permissions: [UserSituation.ADMIN_CALYPSO],
            path: routerLinks.referential.dashboard(),
            background: moduleColors.yellow,
            icon: <Referential />,
            key: "referential_dashboard",
        },
        {
            name: "Ateliers",
            permissions: [
                UserSituation.ADMIN_CALYPSO,
                UserSituation.ADMIN_DDPP,
                UserSituation.ADMIN_DGAL,
                UserSituation.ADMIN_DRAAF,
                UserSituation.USER_DDPP,
                UserSituation.USER_DGAL,
                UserSituation.USER_DRAAF,
            ],
            path: routerLinks.referential.global.workshops(),
            background: moduleColors.yellow,
            icon: <Referential />,
            key: "referential_workshops",
        },
    ];

    const isTouchDevice = () => {
        if ("ontouchstart" in window) {
            return true;
        }
        return false;
    };

    return !updateModules ? (
        <>
            <Stack spacing={2} p={2}>
                <PermissionsCheck
                    requiredPermissions={[
                        UserSituation.FAM_DAM_USER,
                        UserSituation.FAM_DAM_ADMIN,
                        UserSituation.PHARMACIST,
                        UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                        UserSituation.REGISTERED_IN_PRACTICE,
                        UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                    ]}
                >
                    <AlertBanners />
                </PermissionsCheck>
                <Activities />
                <HomeModules modules={modules} setUpdateModules={setUpdateModules} />
            </Stack>
            <HomeVetTour />
            <HomeFamDamTour />
        </>
    ) : (
        <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
            <EditHomeModules modules={modules} setUpdateModules={setUpdateModules} />
        </DndProvider>
    );
}
