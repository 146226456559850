// === NPM
import React, { Dispatch, RefObject, SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, FormControl, Stack, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import FileUploaderWithPreview from "@/components/generics/FileUploaderWithPreview";
import GenericComment from "@/components/generics/inputs/GenericComment";
import { IHealthReportingForm } from "@/components/HealthReporting/AnimalAbuse/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useFormHealthReporting } from "../../../useFormHealthReporting";

interface CommentsAndFilesProps {
    formRef: RefObject<HTMLButtonElement>;
    getValuesRef: RefObject<HTMLButtonElement>;
    handleNextStep: (partialValues: Partial<IHealthReportingForm>, finalStep?: boolean) => void;
    files: File[];
    setFiles: Dispatch<SetStateAction<File[]>>;
    setSaveAndQuit: (value: boolean) => void;
}

const commentsAndFileSchema = z.object({
    comment: z
        .string()
        .trim()
        .max(2000, { message: "Ce champ ne peut pas contenir plus de 2000 caractères" })
        .optional(),
    files: z.array(z.instanceof(File)).max(10, "Vous êtes limité à 10 fichiers"),
});

type CommentsAndFileSchemaValidationSchema = z.infer<typeof commentsAndFileSchema>;

export default function CommentsAndFiles({
    formRef,
    getValuesRef,
    handleNextStep,
    files,
    setFiles,
    setSaveAndQuit,
}: Readonly<CommentsAndFilesProps>) {
    const { form, setForm } = useFormHealthReporting();
    const {
        formState: { errors },
        handleSubmit,
        control,
        getValues,
    } = useForm<CommentsAndFileSchemaValidationSchema>({
        resolver: zodResolver(commentsAndFileSchema),
        defaultValues: {
            comment: form?.comment ?? "",
            files: files,
        },
    });

    const checkSize = (file: File) => {
        if (file.size > 1024 * 1024 * 5) {
            return {
                code: "file-too-large",
                message: `La taille des fichiers ne doit pas excéder 5 Mo.`,
            };
        }
    };

    const validateCommentsAndFiles = (formValues: CommentsAndFileSchemaValidationSchema) => {
        setFiles(formValues.files);
        handleNextStep({ comment: formValues.comment }, true);
    };

    const getCommentsAndFilesValues = () => {
        const values = getValues();
        setFiles(values.files);
        setForm({ ...form, comment: values.comment });
        setSaveAndQuit(true);
    };

    const renderComment = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <FormControl fullWidth error={!!errors.comment} required>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <GenericComment
                                value={value}
                                label="Commentaire"
                                onChange={onChange}
                                helperText={error?.message}
                                rows={6}
                                placeholder="Détails complémentaires au signalement"
                            />
                        )}
                    />
                </FormControl>
            </StyledCardContent>
        </Card>
    );

    const renderFiles = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <FormControl fullWidth error={!!errors.files} required>
                    <Controller
                        name="files"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <>
                                <FileUploaderWithPreview
                                    files={value}
                                    setFiles={(fileList) => {
                                        onChange(fileList);
                                    }}
                                    acceptedFiles={["application/pdf", "image/jpg", "image/jpeg", "image/png"]}
                                    maxFiles={10}
                                    validator={checkSize}
                                    info="Les formats acceptés sont .jpg, .jpeg, .png, .pdf et la taille des fichiers ne doit pas excéder 5 Mo. Vous pouvez ajouter jusqu'à 10 fichiers."
                                />
                                {error?.message && (
                                    <Typography sx={{ textAlign: "center" }} color="error">
                                        {error?.message}
                                    </Typography>
                                )}
                            </>
                        )}
                    />
                </FormControl>
            </StyledCardContent>
        </Card>
    );

    return (
        <Stack spacing={3} width="100%">
            <form onSubmit={handleSubmit(validateCommentsAndFiles)} noValidate>
                <Stack spacing={3} width="100%">
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Commentaire
                    </Typography>
                    {renderComment()}
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Documents complémentaires
                    </Typography>
                    {renderFiles()}
                </Stack>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
                <button style={{ display: "none" }} ref={getValuesRef} onClick={() => getCommentsAndFilesValues()} />
            </form>
        </Stack>
    );
}
