export const colors = {
    white: "#ffffff",
    whiteOpacity90: "#ffffffe6",
    black: "#000",
    primaryColor: "#228661",
    secondaryColor: "#5e7469",
    lightGray: "#F5F5F5",
    error: "#d02d26",
    success: "#3ba55d",
    background: "#eff3f1",
    warning: "#FFF0B3",
    blue: "#005dac",
    orange: "#E67A68",
    blueDelivery: "#185D81",
    green: "#ABDB4A",
    purple: "#C2AFF0",
    lightGreen: "#E8FFF6",
    disabled: "rgba(0, 0, 0, 0.26)",
};

export const moduleColors = {
    purple: "#C2AFF01A",
    blue: "#90D9E11A",
    green: "#ABDB4A1A",
    red: "#E67A681A",
    yellow: "#E9C10D1A",
    orange: "#FFF6F1",
    pink: "#FF90C91A",
};

export const drawerColors = {
    background: "#CEDCD5",
    text: "#7E7E7E",
    border: "#D0D5DD",
};

export const faqColors = {
    unpublish: "#EBEBEB",
};

export const statusColor = {
    errorText: "#81313A",
    errorBackground: "#E0ABAB",
    warningText: "#91812D",
    warningBackground: "#FCF1B3",
    successText: "#39532D",
    successBackground: "#C2E0AB",
};

export const ACTIONS_COLUMN_WIDTH = 140;

export const DeliveryStatusStyle = {
    REJECTED: {
        backgroundColor: "#E0ABAB",
        padding: "2px 5px",
        color: "#81313A",
        fontWeight: 600,
        borderRadius: "5px",
    },
    PARTIAL: {
        backgroundColor: "#F2D7A4",
        padding: "2px 5px",
        color: "#BB681B",
        fontWeight: 600,
        borderRadius: "5px",
    },
    OK: {
        backgroundColor: "#C2E0AB",
        padding: "2px 5px",
        color: "#39532D",
        fontWeight: 600,
        borderRadius: "5px",
    },
    OK_WITH_WARNING: {
        backgroundColor: statusColor.warningBackground,
        padding: "2px 5px",
        color: "#84741D",
        fontWeight: 600,
        borderRadius: "5px",
    },
};

export const NotificationDisplayTypeStyle = {
    GLOBAL: {
        backgroundColor: "#C2E0AB",
        padding: "2px 5px",
        color: "#39532D",
        fontWeight: 600,
        borderRadius: "5px",
    },
    REGULATIONS: {
        backgroundColor: statusColor.warningBackground,
        padding: "2px 5px",
        color: "#84741D",
        fontWeight: 600,
        borderRadius: "5px",
    },
    ALERT: {
        backgroundColor: "#F2D7A4",
        padding: "2px 5px",
        color: "#BB681B",
        fontWeight: 600,
        borderRadius: "5px",
    },
    ALERT_BANNER: {
        backgroundColor: "#E0ABAB",
        padding: "2px 5px",
        color: "#81313A",
        fontWeight: 600,
        borderRadius: "5px",
    },
};

export const CampaignStatusStyle = {
    ERROR: {
        backgroundColor: "#E0ABAB",
        padding: "2px 5px",
        color: "#81313A",
        fontWeight: 600,
        borderRadius: "5px",
    },
    PARTIAL_SUCCESS: {
        backgroundColor: statusColor.warningBackground,
        padding: "2px 5px",
        color: "#84741D",
        fontWeight: 600,
        borderRadius: "5px",
    },
    SUCCESS: {
        backgroundColor: "#C2E0AB",
        padding: "2px 5px",
        color: "#39532D",
        fontWeight: 600,
        borderRadius: "5px",
    },
};
