export interface ICategory {
    uuid: string;
    title: string;
    position: number;
    questionCount: number;
}

export interface ITag {
    uuid: string;
    name: string;
    color: string;
}

export interface IQuestionShort {
    uuid: string;
    title: string;
    position: number;
    published: boolean;
    hasDraft: boolean;
    categoryUuid: string;
}

export interface IQuestion extends IQuestionShort {
    content: string;
    tagUuids: string[];
}

// TODO GLOBAL THIS BEC IT'S USED IN MULTIPLE PLACES
export enum UserTypeHeader {
    ADMIN = "admin",
    USER = "user",
}
