// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Add } from "@/assets/icons/actions/plus.svg";
import GenericButton from "@/components/generics/buttons/GenericButton";
import {
    FilterConfigurations,
    GenericFilters,
    SortingConfiguration,
} from "@/components/generics/filters/GenericFilters";
import { GenericPagination } from "@/components/generics/layout/GenericPagination";
import PageContent from "@/components/generics/layout/PageContent";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { useProvideGlobal } from "@/context/useGlobalContext";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IHealthAccreditationTrainingShort, ITrainingType } from "@/interfaces/training";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { createPayload } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import { ICatalogHealthAccreditationTrainingFilters, IHealthAccreditationCreate } from "../../interface";
import CreateOrUpdateTraining from "./containers/CreateOrUpdateTraining";
import TrainingCard from "./containers/TrainingCard";

type ICatalogHealthAccreditationTrainingSortConfig = Pick<
    IHealthAccreditationTrainingShort,
    "title" | "duration" | "ectsPoints"
>;

export default function CatalogHealthAccreditationTraining() {
    const { loadingTable } = useProvideGlobal();
    const [trainings, setTrainings] = useState<IHealthAccreditationTrainingShort[]>([]);
    const [inputFilters, setInputFilters] = useState<ICatalogHealthAccreditationTrainingFilters>({
        title: "",
        typeUuid: "",
        targetPublic: "",
        duration: ["", ""],
        ectsPoints: "",
        inscriptionNumberMin: "",
        inscriptionNumberMax: "",
        archived: "false",
    });
    const [sort, setSort] = useState({ field: "title", direction: "asc" });
    const [rowCount, setRowCount] = useState<number>(0);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [types, setTypes] = useState<ITrainingType[]>([]);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getTrainings();
    }, [pagination, sort]);

    useEffect(() => {
        getTrainingTypes();
    }, []);

    const getTrainings = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: [`${sort.field},${sort.direction}`],
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getHealthAccreditationTrainings(payload);
        if (res.status === HttpStatus.OK) {
            setTrainings(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const getTrainingTypes = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingTypes();
        if (res.status === HttpStatus.OK) {
            setTypes(res.data);
        }
    };

    const createTraining = async (data: IHealthAccreditationCreate, files?: File[]) => {
        const res = await TrainingService.postHealthAccreditationTraining(data, files ?? null);
        if (res.status === HttpStatus.CREATED) {
            getTrainings();
            setOpenCreateDialog(false);
            toast.success("Formation crée avec succès");
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const renderSkeleton = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
                <Skeleton variant="rounded" width={300} height={350} />
            </Grid>
        </Grid>
    );

    const renderCatalogContent = () =>
        trainings.length > 0 ? (
            <Grid container spacing={2}>
                {trainings.map((training) => (
                    <Grid item xs={12} sm={6} lg={4} xl={3} key={training.internalId}>
                        <TrainingCard training={training} types={types} refresh={getTrainings} />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Typography sx={{ display: "flex", justifyContent: "center" }}>Aucune formation trouvée.</Typography>
        );

    const filterConfigurations: FilterConfigurations<ICatalogHealthAccreditationTrainingFilters> = {
        title: { type: FilterType.INPUT, label: "Titre ou identifiant" },
        typeUuid: {
            type: FilterType.SINGLE_SELECT,
            label: "Type",
            values: types.map((type) => ({ key: type.uuid, label: type.label })),
        },
        targetPublic: { type: FilterType.INPUT, label: "Public cible" },
        duration: { type: FilterType.INTERVAL, label: "Durée" },
        ectsPoints: { type: FilterType.INPUT, label: "Points ECTS" },
        inscriptionNumberMin: { type: FilterType.INPUT, label: "Nombre min d'inscrits" },
        inscriptionNumberMax: { type: FilterType.INPUT, label: "Nombre max d'inscrits" },
        archived: {
            type: FilterType.SINGLE_SELECT,
            label: "Archivée",
            values: [
                { key: "false", label: "Non archivée" },
                { key: "true", label: "Archivée" },
            ],
        },
    };

    const sortingConfiguration: SortingConfiguration<ICatalogHealthAccreditationTrainingSortConfig>[] = [
        {
            label: "Titre de la formation - Alphabétique",
            value: SortDirection.ASC,
            field: "title",
        },
        {
            label: "Titre de la formation - Alphabétique inverse",
            value: SortDirection.DESC,
            field: "title",
        },
        {
            label: "Durée - Croissant",
            value: SortDirection.ASC,
            field: "duration",
        },
        {
            label: "Durée - Décroissant",
            value: SortDirection.DESC,
            field: "duration",
        },
        {
            label: "Nombre de points ECTS - Croissant",
            value: SortDirection.ASC,
            field: "ectsPoints",
        },
        {
            label: "Nombre de points ECTS - Décroissant",
            value: SortDirection.DESC,
            field: "ectsPoints",
        },
    ];

    return (
        <PageContent>
            <Stack height="100%" spacing={2} width="100%">
                <Typography variant="h4">Catalogue des formations au maintien de l'habilitation sanitaire</Typography>
                <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_OVVT]}>
                    <Box display="flex" justifyContent="end">
                        <GenericButton
                            startIcon={<Add />}
                            onClick={() => setOpenCreateDialog(true)}
                            label="Ajouter une formation"
                        />
                    </Box>
                </PermissionsCheck>
                <Box>
                    <Box mb={2}>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                title: "",
                                typeUuid: "",
                                targetPublic: "",
                                duration: ["", ""],
                                ectsPoints: "",
                                inscriptionNumberMin: "",
                                inscriptionNumberMax: "",
                                archived: "",
                            }}
                            setInputFilters={setInputFilters}
                            sortingConfigurations={sortingConfiguration}
                            sort={sort}
                            setSort={setSort}
                        />
                    </Box>
                    {loadingTable ? renderSkeleton() : renderCatalogContent()}
                </Box>

                <GenericPagination
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    pageCount={Math.ceil(rowCount / pagination.pageSize)}
                    setPage={handlePageChange}
                    setPageSize={handlePageSizeChange}
                />
            </Stack>
            {openCreateDialog && (
                <CreateOrUpdateTraining
                    types={types}
                    onClose={() => setOpenCreateDialog(false)}
                    onValid={createTraining}
                />
            )}
        </PageContent>
    );
}
