// === NPM
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL

export default function VaccinationSites() {
    return (
        <Stack width="100%" spacing={2}>
            <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="h4">Facturation des chantiers</Typography>
            </Box>
        </Stack>
    );
}
