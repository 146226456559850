// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { HttpStatus } from "@/interfaces/global";
import { VaccinationInterventionType, VaccineBatch } from "@/interfaces/vaccination";
import { getEnumKeyByValue } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";
import { IVaccinationInterventionDetails } from "../interface";
import AnimalAccordion from "./AnimalAccordion";
import InterventionAccordion from "./InterventionAccordion";

interface ViewDialogProps {
    onClose: () => void;
    intervention: IVaccinationInterventionDetails;
}

export default function ViewDialog({ onClose, intervention }: Readonly<ViewDialogProps>) {
    const [vaccineBatches, setVaccineBatches] = useState<VaccineBatch[]>([]);
    const [diluentBatches, setDiluentBatches] = useState<VaccineBatch[]>([]);

    useEffect(() => {
        getBatches(
            intervention.animalInformation.vaccineBatchUuids,
            intervention.animalInformation.vaccineGtinCode,
            setVaccineBatches
        );
        if (intervention.animalInformation.diluentGtinCode) {
            getBatches(
                intervention.animalInformation.diluentBatchUuids,
                intervention.animalInformation.diluentGtinCode,
                setDiluentBatches
            );
        }
    }, []);

    const getBatches = async (uuids: string[], gtinCode: string, setter: Dispatch<SetStateAction<VaccineBatch[]>>) => {
        const payload = {
            page: 0,
            size: 50,
            uuid: uuids,
        };
        const res = await VaccinationService.getVaccineBatches(gtinCode, payload);
        if (res.status === HttpStatus.OK) {
            setter(res.data);
        }
    };

    const renderContent = () => (
        <Stack spacing={2} width="100%">
            <Typography variant="h5" sx={{ mb: 2 }}>
                Intervention de vaccination{" "}
                {intervention.type ===
                getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY)
                    ? "au couvoir"
                    : "en élevage"}
            </Typography>
            <InterventionAccordion
                vaccinationSiteInformation={intervention.vaccinationSiteInformation}
                workshopId={intervention.generalInformation.workshopId}
                complianceType={intervention.generalInformation.complianceType}
                complianceComment={intervention.generalInformation.complianceComment}
            />
            <AnimalAccordion
                animalInformation={intervention.animalInformation}
                vaccineBatches={vaccineBatches.filter((vb) =>
                    intervention.animalInformation.vaccineBatchUuids.includes(vb.uuid)
                )}
                diluentBatches={diluentBatches}
                vaccinationDate={intervention.vaccinationSiteInformation.date}
                injectionType={intervention.animalInformation.injectionType}
                animalBatches={intervention.animalBatches}
            />
        </Stack>
    );

    const displayActionButton = () => (
        <SecondaryButton onClick={onClose} variant="outlined">
            Fermer
        </SecondaryButton>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Récapitulatif de l'intervention de vaccination n°${intervention.id}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={displayActionButton}
        />
    );
}
