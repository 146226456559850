// === NPM
import React from "react";
import { Done } from "@mui/icons-material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

interface PermissionCellProps {
    checked: boolean;
}
export default function PermissionCell({ checked }: Readonly<PermissionCellProps>) {
    return checked ? <Done sx={{ color: colors.primaryColor }} /> : null;
}
