// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
// === LOCAL
import { ReactComponent as InProcess } from "@/assets/icons/healthreporting/in_process.svg";
import { ReactComponent as New } from "@/assets/icons/healthreporting/new.svg";
import { ReactComponent as Processed } from "@/assets/icons/healthreporting/processed.svg";
import { ReactComponent as Traceability } from "@/assets/icons/healthreporting/traceability.svg";
import { ReactComponent as Edit } from "@/assets/icons/shared/edit_circular.svg";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import {
    GenericPresetFilters,
    PresetFilterConfigurations,
} from "@/components/generics/presetFilters/GenericPresetFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import InfoText from "@/components/generics/text/InfoText";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { UserType } from "@/interfaces/user";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { convertEnumToKeyLabelObject, createPayload, getEnumKeyByValue, toLocaleDateFormat } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import HealthReportingService from "@/services/HealthReportingService";
import {
    HealthReportingStatus,
    HolderType,
    IHealthReporting,
    IHealthReportingCounts,
    IHealthReportingFilters,
    IHealthReportingFull,
    IHealthReportingStatusForm,
} from "../../interface";
import EditDialog from "./containers/EditDialog";
import ViewDialog from "./containers/ViewDialog";

const initialValues = {
    id: "",
    reportingDate: [null, null],
    dpe: "",
    veterinary: "",
    holderType: [],
    holderId: "",
    holderBusinessName: "",
    holder: "",
    holderPostalCode: "",
    holderCity: "",
    holderDepartment: [],
    status: [],
};

export default function ViewHealthReporting() {
    const navigate = useNavigate();
    const auth = useAuth();
    const { departments } = useDepartments();

    const [healthReportings, setHealthReportings] = useState<IHealthReporting[]>([]);
    const [healthReportingsCounts, setHealthReportingsCounts] = useState<IHealthReportingCounts>({
        newStatus: 0,
        inProcessStatus: 0,
        processedStatus: 0,
    });
    const [inputFilters, setInputFilters] = useState<IHealthReportingFilters>({
        id: "",
        reportingDate: [null, null],
        dpe: "",
        veterinary: "",
        holderType: [],
        holderId: "",
        holderBusinessName: "",
        holder: "",
        holderPostalCode: "",
        holderCity: "",
        holderDepartment: [],
        status: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [selectedHealthReporting, setSelectedHealthReporting] = useState<IHealthReportingFull>(null);
    const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getHealthReportingsCounts();
    }, []);

    // needed to make the default filters works in case on refresh directly on the page otherwise the departments are not loaded and we set an empty list
    useEffect(() => {
        setInputFilters((prev) => ({ ...prev, holderDepartment: initDepartments() }));
    }, [departments]);

    useEffect(() => {
        getHealthReportings();
    }, [pagination, sortModel]);

    const getHealthReportings = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await HealthReportingService.getHealthReportings(payload);
        if (res.status === HttpStatus.OK) {
            setHealthReportings(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const getHealthReportingsCounts = async () => {
        const res = await HealthReportingService.getHealthReportingsCounts();
        if (res.status === HttpStatus.OK) {
            setHealthReportingsCounts(res.data);
        }
    };

    const getHealthReporting = async (id: string, setterDialog: Dispatch<SetStateAction<boolean>>) => {
        const res = await HealthReportingService.getHealthReporting(id);
        if (res.status === HttpStatus.OK) {
            setSelectedHealthReporting(res.data);
            setterDialog(true);
        }
    };

    const patchHealthReportingStatus = async (form: IHealthReportingStatusForm) => {
        const res = await HealthReportingService.patchHealthReportingStatus(selectedHealthReporting.id, form);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Statut mis à jour avec succès");
            setOpenEditDialog(false);
            setSelectedHealthReporting(null);
            getHealthReportings();
            getHealthReportingsCounts();
        }
    };

    const initDepartments = (): string[] => {
        if (auth.userInfo.type === UserType.DDPP) {
            const department = departments.find((d) => d.inseeCode === auth.userInfo.properties?.inseeCode);
            if (department) {
                return [department.inseeCode];
            }
        } else if (auth.userInfo.type === UserType.DRAAF) {
            const result = departments.filter((d) => d.regionInseeCode === auth.userInfo.properties?.inseeCode);
            return result.map((d) => d.inseeCode);
        }
        return [];
    };

    const handleDownloadHealthReporting = async (id: string) => {
        const res = await HealthReportingService.getHealthReportingZip(id);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Identifiant signalement",
            flex: 1,
        },
        {
            field: "reportingDate",
            headerName: "Date signalement",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => toLocaleDateFormat(params.row.reportingDate),
        },
        {
            field: "dpeId",
            headerName: "DPE",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => `${params.row.dpeId} - ${params.row.dpeName}`,
        },
        {
            field: "veterinaryId",
            headerName: "Vétérinaire",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.veterinaryId} - ${params.row.veterinaryLastName} ${params.row.veterinaryFirstName}`,
        },
        {
            field: "holderType",
            headerName: "Type de détenteur",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) => HolderType[params.row.holderType],
        },
        {
            field: "holderId",
            headerName: "Identifiant détenteur",
            flex: 1,
        },
        {
            field: "holderBusinessName",
            headerName: "Raison sociale",
            flex: 1,
        },
        {
            field: "holderLastName",
            headerName: "Détenteur",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => `${params.row.holderLastName} ${params.row.holderFirstName}`,
        },
        {
            field: "holderPostalCode",
            headerName: "Code postal",
            flex: 1,
        },
        {
            field: "holderCity",
            headerName: "Commune",
            flex: 1,
        },
        {
            field: "holderInseeCode",
            headerName: "Département",
            flex: 1,

            valueGetter: (params: GridRenderCellParams) => {
                const department = departments.find((d) => d.inseeCode === params.row.holderInseeCode);
                return department ? `${department.inseeCode} - ${department.name}` : "";
            },
        },
        {
            field: "status",
            headerName: "Statut",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => HealthReportingStatus[params.row.status],
        },
        {
            ...ActionsColumnProps,
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction
                        title="Voir le détail"
                        onClick={() => getHealthReporting(params.row.id, setOpenViewDialog)}
                    />
                    <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_DDPP]}>
                        {auth.userInfo.properties?.inseeCode === params.row.holderInseeCode && (
                            <IconActionButton
                                title="Changer le statut"
                                icon={<Edit />}
                                onClick={() => getHealthReporting(params.row.id, setOpenEditDialog)}
                            />
                        )}
                    </PermissionsCheck>
                    <DownloadAction
                        title="Télécharger le signalement"
                        onClick={() => handleDownloadHealthReporting(params.row.id)}
                    />
                    <PermissionsCheck
                        requiredPermissions={[
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.ADMIN_DGAL,
                            UserSituation.ADMIN_DRAAF,
                            UserSituation.USER_DDPP,
                            UserSituation.USER_DGAL,
                            UserSituation.USER_DRAAF,
                        ]}
                    >
                        <IconActionButton
                            title="Voir la traçabilité du signalement"
                            icon={<Traceability />}
                            onClick={() => {
                                navigate(routerLinks.admin.traceability.healthReportings(), {
                                    state: { id: params.row.id },
                                });
                            }}
                        />
                    </PermissionsCheck>
                </Box>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<IHealthReportingFilters> = {
        id: { label: "Identifiant signalement", type: FilterType.INPUT },
        reportingDate: { label: "Date signalement", type: FilterType.DATEPICKER },
        dpe: { label: "DPE", type: FilterType.INPUT },
        veterinary: { label: "Vétérinaire", type: FilterType.INPUT },
        holderType: {
            label: "Type de détenteur",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(HolderType),
        },
        holderId: { label: "Identifiant détenteur", type: FilterType.INPUT },
        holderBusinessName: { label: "Raison sociale", type: FilterType.INPUT },
        holder: { label: "Détenteur", type: FilterType.INPUT },
        holderPostalCode: { label: "Code postal", type: FilterType.INPUT },
        holderCity: { label: "Commune", type: FilterType.INPUT },
        holderDepartment: {
            label: "Département",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
        status: {
            label: "Statut",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(HealthReportingStatus),
        },
    };

    const presetFilterConfigurations: PresetFilterConfigurations<IHealthReportingCounts, IHealthReportingFilters> = {
        newStatus: {
            icon: <New />,
            title: "Nouveaux signalements",
            value: healthReportingsCounts?.newStatus,
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(HealthReportingStatus, HealthReportingStatus.NEW)],
                holderDepartment: initDepartments(),
            },
        },
        inProcessStatus: {
            icon: <InProcess />,
            title: "En cours de traitement",
            value: healthReportingsCounts?.inProcessStatus,
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(HealthReportingStatus, HealthReportingStatus.IN_PROCESS)],
                holderDepartment: initDepartments(),
            },
        },
        processedStatus: {
            icon: <Processed />,
            title: "Signalements traités",
            value: healthReportingsCounts?.processedStatus,
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(HealthReportingStatus, HealthReportingStatus.PROCESSED)],
                holderDepartment: initDepartments(),
            },
        },
    };

    return (
        <>
            <Stack height="100%" spacing={2} width="100%">
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography variant="h4">Suivi de mes signalements de maltraitance</Typography>
                    <PermissionsCheck requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}>
                        <GenericButton
                            startIcon={<Add />}
                            label="Déclarer un signalement de maltraitance"
                            onClick={() => navigate(routerLinks.healthReporting.animalAbuse.form())}
                            id="create-user-btn"
                        />
                    </PermissionsCheck>
                </Box>
                <GenericPresetFilters
                    superFilterConfiguration={presetFilterConfigurations}
                    inputFilters={inputFilters}
                    setInputFilters={setInputFilters}
                    clearFilters={() => setInputFilters(initialValues)}
                />
                <Card>
                    <CardContent>
                        <InfoText message="Sans préjudice des autres obligations déclaratives que leur impose le présent livre, les vétérinaires sanitaires informent sans délai l'autorité administrative des manquements à la réglementation relative à la santé publique vétérinaire qu'ils constatent dans les lieux au sein desquels ils exercent leurs missions si ces manquements sont susceptibles de présenter un danger grave pour les personnes ou les animaux. (L203-6 CRPM)." />
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                reportingDate: [null, null],
                                dpe: "",
                                veterinary: "",
                                holderType: [],
                                holderId: "",
                                holderBusinessName: "",
                                holder: "",
                                holderPostalCode: "",
                                holderCity: "",
                                holderDepartment: [],
                                status: [],
                            }}
                            setInputFilters={setInputFilters}
                        />

                        <GenericTable
                            rows={healthReportings}
                            columns={columns}
                            getRowId={(row) => row.id}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={["asc", "desc"]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {openViewDialog && (
                <ViewDialog
                    onClose={() => {
                        setOpenViewDialog(false);
                        setSelectedHealthReporting(null);
                    }}
                    healthReporting={selectedHealthReporting}
                />
            )}
            {openEditDialog && (
                <EditDialog
                    onClose={() => {
                        setOpenEditDialog(false);
                        setSelectedHealthReporting(null);
                    }}
                    onValid={(form: IHealthReportingStatusForm) => patchHealthReportingStatus(form)}
                    healthReporting={selectedHealthReporting}
                />
            )}
        </>
    );
}
