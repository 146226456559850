import React from "react";
import { Box, Typography } from "@mui/material";

interface IconBannerTextProps {
    message: string;
    icon: React.ReactElement;
    color: string;
    backgroundColor: string;
}
export default function IconBannerText({ message, icon, color, backgroundColor }: Readonly<IconBannerTextProps>) {
    return (
        <Box
            display="flex"
            alignItems="flex-start"
            sx={{ backgroundColor: backgroundColor, borderRadius: 1, gap: 2, px: 1, py: 0.5 }}
        >
            <Box display="flex" alignItems="center" sx={{ color: color }}>
                {icon}
            </Box>
            <Typography color={color} variant="bold" sx={{ whiteSpace: "pre-line" }}>
                {message}
            </Typography>
        </Box>
    );
}
