// === NPM
import React from "react";
import { CloseRounded } from "@mui/icons-material";
import { Chip, styled } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

const StyledChip = styled(Chip)(() => ({
    "border": `3px solid ${colors.primaryColor}`,
    "color": `${colors.primaryColor}`,

    "& .MuiChip-label": {
        color: colors.primaryColor,
        fontSize: 16,
        fontWeight: 600,
    },

    "& .MuiChip-deleteIcon": {
        "color": colors.primaryColor,
        "&:hover": {
            color: colors.primaryColor,
        },
    },

    "&:hover": {
        backgroundColor: colors.lightGreen,
    },
}));

StyledChip.defaultProps = {
    deleteIcon: <CloseRounded />,
    variant: "outlined",
};

export default StyledChip;
