// === NPM
import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { UserType } from "@/interfaces/user";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";

export default function Billing() {
    const auth = useAuth();

    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.iahp.billing.vaccinationSites(),
                        label: "Facturation des chantiers",
                    },
                    {
                        url: routerLinks.iahp.billing.vaccinationSitesOutsideCalypso(),
                        label: "Facturation des chantiers hors Calypso",
                    },
                    {
                        url: routerLinks.iahp.billing.memories(),
                        label: "Mémoires de paiement",
                    },
                    {
                        url: routerLinks.iahp.billing.management(),
                        label: "Référentiels de facturation",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                    },
                ]}
            />
            <PageContent>
                {auth.userInfo.type !== UserType.VETERINARY ||
                auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) ? (
                    <Outlet />
                ) : (
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                        <Typography variant="h4">
                            Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                            vétérinaire sanitaire.
                        </Typography>
                    </Box>
                )}
            </PageContent>
        </>
    );
}
