// === NPM
import React, { Dispatch, RefObject, SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Delete, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import GenericErrorMessageText from "@/components/generics/text/GenericErrorMessageText";
import {
    HealthReportingOutletContext,
    IAnomalyType,
    IReportedAnomaly,
} from "@/components/HealthReporting/AnimalAbuse/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { ISpeciesDetails } from "@/interfaces/referential";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";

interface AnomalyProps {
    initialValues: IReportedAnomaly;
    anomalyRef: RefObject<Record<number, HTMLButtonElement>>;
    anomalyGetValuesRef: RefObject<Record<number, HTMLButtonElement>>;
    handleUpdateAnomaly: (internalId: string, anomaly: IReportedAnomaly) => void;
    handleDeleteAnomaly: (internalId: string) => void;
    anomaliesLength: number;
    index: number;
    setValidAnomalies: Dispatch<SetStateAction<boolean[]>>;
    setValidSaveDraft: Dispatch<SetStateAction<boolean[]>>;
}

const positiveNumberWithSmallDecimal = "^([0-9]+(\\.[0-9]{1,2})?)$";

const anomalySchema = z.object({
    anomalyTypeUuid: stringRequired(),
    species: stringRequired(),
    animalCount: z.coerce
        .number({
            required_error: FORM_TEXT.required,
            invalid_type_error: "Veuillez saisir un nombre positif",
        })
        .positive({ message: "Veuillez saisir un nombre positif" })
        .refine(
            (value) => RegExp(positiveNumberWithSmallDecimal).test(value.toString()),
            "Vous pouvez saisir jusqu'à 2 décimales"
        ),
    individualIdentification: z.string().trim().optional(),
});

export type AnomalyValidationSchema = z.infer<typeof anomalySchema>;

export default function Anomaly({
    initialValues,
    anomalyRef,
    anomalyGetValuesRef,
    handleUpdateAnomaly,
    handleDeleteAnomaly,
    setValidAnomalies,
    anomaliesLength,
    index,
    setValidSaveDraft,
}: Readonly<AnomalyProps>) {
    const { anomalyTypes, species } = useOutletContext<HealthReportingOutletContext>();
    const {
        formState: { errors },
        handleSubmit,
        control,
        getValues,
    } = useForm<AnomalyValidationSchema>({
        resolver: zodResolver(anomalySchema),
        defaultValues: {
            anomalyTypeUuid: initialValues?.anomalyTypeUuid ?? "",
            species: initialValues?.species ?? "",
            animalCount: initialValues?.animalCount ?? undefined,
            individualIdentification: initialValues?.individualIdentification ?? "",
        },
    });

    const updateNewAnomaly = (data: AnomalyValidationSchema) => {
        const newAnomaly: IReportedAnomaly = {
            anomalyTypeUuid: data.anomalyTypeUuid,
            species: data.species,
            animalCount: data.animalCount,
            individualIdentification: data.individualIdentification,
            internalId: initialValues.internalId,
        };
        handleUpdateAnomaly(initialValues.internalId, newAnomaly);
        setValidAnomalies((prev) => prev.map((v, i) => (i === index ? true : v)));
    };

    const getAnomalyValues = () => {
        const values = getValues();
        handleUpdateAnomaly(initialValues.internalId, values as IReportedAnomaly);
        setValidSaveDraft((prev) => prev.map((v, i) => (i === index ? true : v)));
    };

    return (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Stack spacing={1}>
                    <Box justifyContent="space-between" display="flex" alignItems="center">
                        <Typography variant="bold">Anomalie {index + 1}</Typography>
                        {anomaliesLength > 1 && (
                            <Button
                                startIcon={<Delete />}
                                color="error"
                                onClick={() => handleDeleteAnomaly(initialValues.internalId)}
                            >
                                Supprimer l'anomalie
                            </Button>
                        )}
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth error={!!errors.anomalyTypeUuid} required>
                                <InputLabel>Anomalie</InputLabel>
                                <Controller
                                    name="anomalyTypeUuid"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={field.value ?? ""}
                                            label="Anomalie"
                                            IconComponent={KeyboardArrowDown}
                                        >
                                            {anomalyTypes.map((anomaly: IAnomalyType) => (
                                                <MenuItem key={anomaly.uuid} value={anomaly.uuid}>
                                                    {anomaly.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <GenericErrorMessageText fieldError={errors?.anomalyTypeUuid} />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth error={!!errors.species} required>
                                <InputLabel>Espèce</InputLabel>
                                <Controller
                                    name="species"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            value={field.value ?? ""}
                                            label="Espèce"
                                            IconComponent={KeyboardArrowDown}
                                        >
                                            {species.map((species: ISpeciesDetails) => (
                                                <MenuItem key={species.uuid} value={species.uuid}>
                                                    {species.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                <GenericErrorMessageText fieldError={errors?.species} />
                            </FormControl>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <FormControl fullWidth error={!!errors.animalCount} required>
                                <Controller
                                    name="animalCount"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <GenericTextField
                                            value={value ?? ""}
                                            onChange={onChange}
                                            label="Nombre d'animaux"
                                            required
                                            name="animalCount"
                                            error={!!error}
                                            helperText={error?.message}
                                            maxLength={10}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth error={!!errors.individualIdentification} required>
                                <Controller
                                    name="individualIdentification"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <GenericComment
                                            value={value}
                                            label="Identification individuelle des animaux"
                                            onChange={onChange}
                                            helperText={error?.message}
                                            rows={6}
                                            placeholder="Veuillez saisir l'identification individuelle des animaux concernés"
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>
            </StyledCardContent>
            <button
                style={{ display: "none" }}
                onClick={handleSubmit(updateNewAnomaly)}
                ref={(el) => (anomalyRef.current[index] = el)}
            />
            <button
                style={{ display: "none" }}
                onClick={() => getAnomalyValues()}
                ref={(el) => (anomalyGetValuesRef.current[index] = el)}
            />
        </Card>
    );
}
