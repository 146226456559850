// === NPM
import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import * as DOMPurify from "dompurify";
// === LOCAL
import { ReactComponent as Duration } from "@/assets/icons/shared/duration.svg";
import { ReactComponent as Document } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Star } from "@/assets/icons/shared/star.svg";
import { ReactComponent as Attendees } from "@/assets/icons/training/catalog/attendees.svg";
import { ReactComponent as Type } from "@/assets/icons/training/catalog/type.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { HttpStatus } from "@/interfaces/global";
import { IHealthAccreditationTraining } from "@/interfaces/training";
import { routerLinks } from "@/routers/RouterConstant";
import LocalStorageService from "@/services/LocalStorageService";
import TrainingService from "@/services/TrainingService";

interface ViewDialogProps {
    training: IHealthAccreditationTraining;
    onClose: () => void;
}

export default function ViewDialog({ training, onClose }: Readonly<ViewDialogProps>) {
    const onFilePreview = async (fileUuid: string) => {
        const file: File | null = await getTrainingFile(fileUuid);
        if (!file) return;
        openPreview(file);
    };

    const openPreview = (file: File) => {
        const title = file.name;
        const url = URL.createObjectURL(file);
        LocalStorageService.setLocaleStorageItem(title, url);
        LocalStorageService.setLocaleStorageItem(`${title}Type`, file.type);
        window.open(routerLinks.filePreview.dynamicPath(title), title);
    };

    const getTrainingFile = async (fileUuid: string): Promise<File> => {
        const res = await TrainingService.getHealthAccreditationTrainingFile(training.uuid, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            const fileName = res.headers["content-disposition"].split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers["content-type"],
            });
            return newFile;
        }

        return null;
    };

    return (
        <GenericDialog
            title={`${training.internalId} : ${training.title}`}
            onClose={onClose}
            renderActions={() => <GenericActionsDialog onClose={onClose} closeLabel="Retour" displaySubmit={false} />}
            renderContent={() => (
                <Stack spacing={1}>
                    <GenericAccordion title="Informations générales" defaultExpanded>
                        <Stack spacing={1} m={1}>
                            <GenericIconText
                                icon={<Star />}
                                text={`${training.ectsPoints} ECTS`}
                                iconTooltip="Points ECTS"
                            />
                            <GenericIconText
                                icon={<Type />}
                                text={training.type.label}
                                iconTooltip="Type de la formation"
                            />
                            <GenericIconText icon={<Pet />} text={training.targetPublic} iconTooltip="Public cible" />
                            <GenericIconText
                                icon={<Attendees />}
                                text={`${training.inscriptionNumberMin} à ${training.inscriptionNumberMax} participants`}
                                iconTooltip="Participants"
                            />
                            <GenericIconText icon={<Duration />} text={`${training.duration}h`} iconTooltip="Durée" />
                            {training?.attachedFiles?.map((attachment) => (
                                <Stack direction="row" spacing={1} alignItems="center" key={attachment.uuid}>
                                    <Tooltip title="Pièce jointe">{<Document />}</Tooltip>
                                    <Typography
                                        component="span"
                                        sx={{
                                            "textDecoration": "underline",
                                            "&:hover": {
                                                cursor: "pointer",
                                            },
                                        }}
                                        onClick={() => onFilePreview(attachment.uuid)}
                                    >
                                        {attachment.filename}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </GenericAccordion>
                    <GenericAccordion title="Contenu et objectifs de la formation" defaultExpanded>
                        <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(training.content),
                            }}
                        />
                    </GenericAccordion>
                </Stack>
            )}
        />
    );
}
