// === NPM
import React, { useEffect, useState } from "react";
// === LOCAL
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import { useFormIntervention } from "@/components/VaccinationIahp/VaccinationSite/useFormIntervention";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { InjectionType } from "@/interfaces/vaccination";
import { getEnumKeyByValue } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";

interface InterventionAutocompleteProps {
    value: string;
    handleChange: (event) => void;
    error: string;
    vaccinationDate: string;
}

export default function InterventionAutocomplete({
    value,
    handleChange,
    error,
    vaccinationDate,
}: Readonly<InterventionAutocompleteProps>) {
    const { form } = useFormIntervention();
    const [interventionIds, setInterventionIds] = useState<{ id: string }[]>([]);
    const [batchSearch, setBatchSearch] = useState<string>(value ?? "");

    useEffect(() => {
        if (batchSearch) getInterventionIds();
    }, []);

    useTimeout(() => getInterventionIds(), [batchSearch]);

    const getInterventionIds = async () => {
        const injectionTypes = [];
        if (form.animalInformation.injectionType === getEnumKeyByValue(InjectionType, InjectionType.REMINDER)) {
            injectionTypes.push(
                getEnumKeyByValue(InjectionType, InjectionType.SECOND_DOSE),
                getEnumKeyByValue(InjectionType, InjectionType.REMINDER)
            );
        } else {
            injectionTypes.push(getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE));
        }
        const res = await VaccinationService.getInterventions("id", {
            page: 0,
            size: 50,
            id: batchSearch,
            injectionType: injectionTypes,
            vaccinationDateBefore: vaccinationDate,
            speciesUuid: form.animalInformation.speciesUuid,
            subSpeciesUuid: form.animalInformation.subSpeciesUuid,
        });
        if (res.status === HttpStatus.OK) {
            setInterventionIds(res.data);
        }
    };

    return (
        <GenericAutocomplete
            label="Numéro d’intervention de vaccination précédente"
            options={interventionIds}
            value={interventionIds.find((o) => o.id === value) || null}
            onChange={handleChange}
            required
            inputValue={batchSearch}
            onInputChange={(_, newInputValue) => {
                setBatchSearch(newInputValue);
            }}
            getOptionLabel={(option) => option.id}
            error={!!error}
            helperText={error}
            noOptionsText={batchSearch ? "Aucun résultat" : "Disponible sur le certificat de la vaccination précédente"}
        />
    );
}
