// === NPM
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { ITrainingSessionCreate } from "@/components/HealthAccreditationTraining/interface";
import { HttpStatus } from "@/interfaces/global";
import { IRegion } from "@/interfaces/referential";
import { IHealthAccreditationTrainingShort, SessionMode } from "@/interfaces/training";
import { IAgriMinistryGeoUnitShort } from "@/interfaces/user";
import { getEnumKeyByValue } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import ReferentialService from "@/services/ReferentialService";
import TrainingService from "@/services/TrainingService";
import FormStepper from "./containers/FormStepper";
import SessionInformations from "./containers/SessionInformations/SessionInformations";
import SessionLocation from "./containers/SessionLocation/SessionLocation";
import Trainers from "./containers/Trainers/Trainers";

export function Form() {
    const formRef = useRef<HTMLButtonElement>(null);
    const backRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();

    const location = useLocation();
    const state = location.state;
    const initialValues = {
        trainingUuid: state?.healthAccreditationTraining?.uuid ?? "",
        startDate: state?.startDate ?? null,
        inscriptionNumberMin: state?.inscriptionNumberMin ? +state.inscriptionNumberMin : null,
        inscriptionNumberMax: state?.inscriptionNumberMax ? +state.inscriptionNumberMax : null,
        comment: state?.comment ?? null,
        ddppUuid: state?.ddpp?.uuid ?? null,
        trainingSessionMode: state?.trainingSessionMode ?? getEnumKeyByValue(SessionMode, SessionMode.FACE_TO_FACE),
        address: state?.address ?? null,
        complementaryAddress: state?.complementaryAddress ?? null,
        postalCode: state?.postalCode ?? null,
        city: state?.city ?? null,
        trainers: state?.trainers ?? [
            {
                trainerType: "",
                veterinaryId: null,
                lastname: null,
                firstname: null,
            },
        ],
    };

    const [activeStep, setActiveStep] = useState<number>(0);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [form, setForm] = useState<ITrainingSessionCreate>(initialValues);
    const [trainings, setTrainings] = useState<IHealthAccreditationTrainingShort[]>([]);
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [selectedDdpp, setSelectedDdpp] = useState<IAgriMinistryGeoUnitShort>(null);

    useEffect(() => {
        Promise.all([getTrainings(), getRegions()]);
    }, []);

    const getTrainings = async () => {
        const payload = { page: 0, size: null };
        const res = await TrainingService.getHealthAccreditationTrainings(payload);
        if (res.status === HttpStatus.OK) {
            setTrainings(res.data);
        }
    };

    const getRegions = async () => {
        const res = await ReferentialService.getRegions();
        if (res.status === HttpStatus.OK) {
            setRegions(res.data);
        }
    };

    const handleStepChange = (partialFormValues: Partial<ITrainingSessionCreate>, previous: boolean = false) => {
        setForm((prev) => ({ ...prev, ...partialFormValues }));
        setActiveStep(activeStep + (previous ? -1 : 1));
    };

    const handleConfirmCancel = (confirm: boolean) => {
        if (confirm) {
            navigate(routerLinks.healthAccreditationTraining.sessions.view());
        } else {
            setOpenConfirmCancelDialog(false);
        }
    };

    const handleSubmit = async (partialFormValues: Partial<ITrainingSessionCreate>, previous: boolean = false) => {
        const finalForm = { ...form, ...partialFormValues };

        if (previous) {
            setForm(finalForm);
            setActiveStep(activeStep - 1);
            return;
        }

        if (state?.uuid) {
            updateSession(finalForm);
        } else {
            createSession(finalForm);
        }
    };

    const updateSession = async (data: ITrainingSessionCreate) => {
        const res = await TrainingService.putHealthAccreditationTrainingSession(state.uuid, data);
        if (res.status === HttpStatus.OK) {
            toast.success("Session mise à jour avec succès.");
            navigate(routerLinks.healthAccreditationTraining.sessions.view());
        }
    };

    const createSession = async (data: ITrainingSessionCreate) => {
        const res = await TrainingService.postHealthAccreditationTrainingSession(data);
        if (res.status === HttpStatus.CREATED) {
            toast.success("Session créée avec succès.");
            navigate(routerLinks.healthAccreditationTraining.sessions.view());
        }
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <SessionInformations
                        form={form}
                        formRef={formRef}
                        onStepChange={handleStepChange}
                        trainings={trainings}
                        disabled={!!state?.uuid}
                    />
                );
            case 1:
                return (
                    <SessionLocation
                        form={form}
                        formRef={formRef}
                        backRef={backRef}
                        onStepChange={handleStepChange}
                        selectedDdpp={selectedDdpp}
                        setSelectedDdpp={setSelectedDdpp}
                        regions={regions}
                        ddppName={state?.ddppName}
                    />
                );
            case 2:
                return <Trainers form={form} formRef={formRef} backRef={backRef} onStepChange={handleSubmit} />;
        }
    };

    return (
        <Stack height="100%" width="100%" px={2} spacing={3}>
            <Typography variant="h4">Nouvelle session de formation</Typography>
            <FormStepper
                activeStep={activeStep}
                handleNextStep={() => {
                    formRef.current.click();
                }}
                handlePreviousStep={() => backRef.current.click()}
                setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
            >
                <Box sx={{ flexGrow: 1, my: 4 }}>{renderSteps()}</Box>
            </FormStepper>
            {openConfirmCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleConfirmCancel}
                />
            )}
        </Stack>
    );
}
