// === Import: NPM
import React from "react";
import { Box } from "@mui/material";
// === Import: LOCAL
import PermissionCell from "@/components/generics/cells/PermissionCell";
import GenericTable from "@/components/generics/table/GenericTable";
import { GenericGridColumns } from "@/interfaces/global";
import { IPermission } from "@/interfaces/user";
import { CREATE, DELETE, READ, UPDATE } from "@/resources/PermissionConstant";
import { IRight } from "../interface";

interface PermissionTableProps {
    profileRights: IPermission;
    rights: IRight[];
}

export default function PermissionTable({ profileRights, rights }: Readonly<PermissionTableProps>) {
    const checkRights = (userRight: number, permissionToCheck: number): boolean => {
        return (userRight & permissionToCheck) > 0;
    };

    const columns: GenericGridColumns[] = [
        {
            field: "label",
            headerName: "Fonctionnalité",
            flex: 2,
            sortable: false,
        },
        {
            field: "read",
            headerName: "Lecture",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) => {
                return <PermissionCell checked={checkRights(profileRights[params.row.key], READ) ?? false} />;
            },
            sortable: false,
        },
        {
            field: "create",
            headerName: "Création",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) => {
                return <PermissionCell checked={checkRights(profileRights[params.row.key], CREATE) ?? false} />;
            },
            sortable: false,
        },
        {
            field: "update",
            headerName: "Modification",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) => {
                return <PermissionCell checked={checkRights(profileRights[params.row.key], UPDATE) ?? false} />;
            },
            sortable: false,
        },
        {
            field: "delete",
            headerName: "Suppression",
            headerAlign: "center",
            align: "center",
            flex: 1,
            renderCell: (params) => {
                return <PermissionCell checked={checkRights(profileRights[params.row.key], DELETE) ?? false} />;
            },
            sortable: false,
        },
    ];

    return (
        <Box
            sx={{
                "& .centered": {
                    justifyContent: "center",
                },
            }}
        >
            <GenericTable
                rows={profileRights ? rights.filter((right) => Object.keys(profileRights).includes(right.key)) : []}
                columns={columns}
                hideFooter
                autoHeight
                getRowId={(row) => row.key}
                noRowsMessage="En attente de sélection"
            />
        </Box>
    );
}
