// === NPM
import React from "react";
import { Card, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useForm } from "@/hooks/useForm";
import { IMonitoringInterventionDetails, InterventionCorrectionType } from "@/interfaces/vaccination";
import { defaultTextLimit } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { FORM_TEXT } from "@/resources/FormUtils";

interface CorrectionDialogProps {
    onClose: () => void;
    onValid: (reason: string) => void;
    correctionType: InterventionCorrectionType;
    intervention: IMonitoringInterventionDetails;
}

interface CorrectionField {
    reason: string;
}

export default function CorrectionDialog({
    onClose,
    onValid,
    correctionType,
    intervention,
}: Readonly<CorrectionDialogProps>) {
    const { data, errors, handleSubmit, handleChange } = useForm<CorrectionField>({
        initialValues: {
            reason: "",
        },
        validations: {
            reason: {
                required: {
                    value: true,
                    message: FORM_TEXT.required,
                },
                custom: {
                    isValid: (value) => value.length < 2000,
                    message: "Le motif de correction est limité à 2000 caractères",
                },
            },
        },
        onSubmit: () => {
            onValid(data.reason);
        },
    });

    const renderContent = () =>
        intervention && (
            <Stack spacing={2} width="100%">
                <Typography variant="h5" sx={{ mb: 2 }}>
                    {`Intervention de surveillance ${intervention.generalInformation.id}`}
                </Typography>
                <IconBannerText
                    message={
                        correctionType === InterventionCorrectionType.DELETE
                            ? `Attention : la suppression est définitive et supprime également le certificat de vaccination dans Calypso.
                              Point de vigilance : Les certificats déjà édités et transmis ne seront plus valables.`
                            : "Attention : La fonctionnalité de correction ne modifie pas le certificat d'intervention de surveillance déjà généré."
                    }
                    color={statusColor.warningText}
                    backgroundColor={statusColor.warningBackground}
                    icon={<Warning />}
                />
                <Card sx={{ p: 2 }}>
                    <StyledCardContent>
                        <GenericTextField
                            label={`Motif de ${
                                correctionType === InterventionCorrectionType.DELETE ? "suppression" : "correction"
                            } de l'intervention`}
                            value={data.reason}
                            helperText={errors.reason ?? `${data.reason.length}/${defaultTextLimit}`}
                            error={!!errors.reason}
                            onChange={handleChange("reason")}
                            rows={3}
                            maxLength={defaultTextLimit}
                            required
                        />
                    </StyledCardContent>
                </Card>
            </Stack>
        );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`${
                correctionType === InterventionCorrectionType.DELETE
                    ? "Suppression de l'intervention de surveillance"
                    : "Correction de l'intervention de surveillance"
            }`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => (
                <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit} validLabel="Continuer" />
            )}
        />
    );
}
