// === NPM
import React from "react";
import { Box, ButtonBase, Paper, Stack, Typography } from "@mui/material";
// === LOCAL
import { colors } from "@/resources/CssConstant";

interface PresetFilterProps {
    icon: JSX.Element;
    title: string;
    value: number;
    onClick: () => void;
    selected?: boolean;
}

const styles = {
    original: { width: 1, height: 1, borderRadius: 5 },
    selected: { width: 1, height: 1, borderRadius: 5, border: "3px solid " + colors.primaryColor },
};

export default function PresetFilter({ icon, title, value, onClick, selected = false }: Readonly<PresetFilterProps>) {
    return (
        <ButtonBase onClick={onClick} sx={selected ? styles.selected : styles.original}>
            <Paper sx={{ width: 1, height: 1, padding: 3, borderRadius: 5 }}>
                <Stack direction="row" spacing={2} alignItems="flex-end">
                    <Box>{icon}</Box>
                    <Stack alignItems="flex-start" color={colors.primaryColor}>
                        <Typography variant="bold" fontSize={25}>
                            {value}
                        </Typography>
                        <Typography variant="bold" fontSize={15}>
                            {title.toLocaleUpperCase()}
                        </Typography>
                    </Stack>
                </Stack>
            </Paper>
        </ButtonBase>
    );
}
