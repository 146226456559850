// === Import: NPM
import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
// === Import: LOCAL
import { IPatch, TraceabilityActions } from "@/interfaces/traceability";
import { GenericText } from "./GenericText";

interface TitleTextPatchValueProps {
    title?: string;
    value: string | number;
    patches: IPatch[];
    action: string;
    path: string;
    icon?: React.ReactElement;
    iconTooltip?: string;
}
export default function TitleTextPatchValue({
    title,
    value,
    patches,
    action,
    path,
    icon,
    iconTooltip,
}: Readonly<TitleTextPatchValueProps>) {
    //IF NEEDED : on pourrait rajouter un state pour les couleurs,
    //qui prend en compte l'op associée, if /add, /remove, /update changer la couleur.
    //penser à mettre un message "valeur supprimée" ou qqchose du genre pour pouvoir
    //Afficher la couleur dans le cas d'une valeur supprimée, sinon on verra R
    const wasEdited = () => {
        return patches.find((patchObject) => patchObject.path.includes(path));
    };

    return (
        <Stack direction="row" spacing={1}>
            {icon && <Tooltip title={iconTooltip}>{icon}</Tooltip>}

            {title && <Typography variant="bold">{`${title}\u00A0: `}</Typography>}
            <Typography
                variant={wasEdited() && action.includes(TraceabilityActions.UPDATE) ? "patched" : "body1"}
                sx={{ wordBreak: "break-all" }}
            >
                {value}
            </Typography>
        </Stack>
    );
}
