// === NPM
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";

export default function Sessions() {
    const navigate = useNavigate();

    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.healthAccreditationTraining.sessions.view(),
                        label: "Suivi des sessions",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.USER_DDPP,
                            UserSituation.ADMIN_OVVT,
                            UserSituation.USER_OVVT,
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.LIBERAL_VETERINARY,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ],
                    },
                ]}
            />
            <PageContent>
                <Outlet />
            </PageContent>
        </>
    );
}
