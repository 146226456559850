export const routerLinks = {
    login: "/connexion",
    loginPsc: "/connexionPsc",
    home: "/accueil",
    profile: "/profil",
    retrieveKey: "/recuperation-clef",
    faq: "/foire-aux-questions",
    notifications: "/notifications",
    downloads: "/telechargements",
    filePreview: {
        path: "/fichier-calypso/:fileName",
        dynamicPath: (fileName: string) => `/fichier-calypso/${fileName}`,
    },
    admin: {
        base: "/administration",
        profile: () => `${routerLinks.admin.base}/profils`,
        users: {
            base: () => `${routerLinks.admin.base}/utilisateurs`,
            global: () => `${routerLinks.admin.users.base()}/global`,
            famDam: () => `${routerLinks.admin.users.base()}/fam-dam`,
            ma: () => `${routerLinks.admin.users.base()}/ministere-agriculture`,
        },
        apiKey: {
            base: () => `${routerLinks.admin.base}/clef-api`,
            delivery: () => `${routerLinks.admin.apiKey.base()}/cession-medicaments`,
            siPartners: () => `${routerLinks.admin.apiKey.base()}/si-partenaires`,
            continuousTraining: () => `${routerLinks.admin.apiKey.base()}/formation-continues`,
            alerts: () => `${routerLinks.admin.apiKey.base()}/alertes`,
        },
        traceability: {
            base: () => `${routerLinks.admin.base}/tracabilite`,
            organizations: () => `${routerLinks.admin.traceability.base()}/organismes`,
            users: () => `${routerLinks.admin.traceability.base()}/utilisateurs`,
            nonDelivery: () => `${routerLinks.admin.traceability.base()}/non-cession`,
            famDamAgencies: () => `${routerLinks.admin.traceability.base()}/groupes-fam-dam`,
            healthReportings: () => `${routerLinks.admin.traceability.base()}/signalements-maltraitance`,
            ovvt: () => `${routerLinks.admin.traceability.base()}/ovvt`,
            healthAccreditationTrainings: () =>
                `${routerLinks.admin.traceability.base()}/formations-habilitation-sanitaire`,
            healthAccreditationTrainingSessions: () =>
                `${routerLinks.admin.traceability.base()}/sessions-formation-habilitation-sanitaire`,
        },

        faq: {
            base: () => `${routerLinks.admin.base}/faq`,
            questions: () => `${routerLinks.admin.faq.base()}/questions`,
            categoryAndTag: () => `${routerLinks.admin.faq.base()}/categories-et-tags`,
        },
        notifications: {
            base: () => `${routerLinks.admin.base}/notifications`,
            gestion: () => `${routerLinks.admin.notifications.base()}/gestion`,
            form: () => `${routerLinks.admin.notifications.base()}/formulaire`,
            automatic: () => `${routerLinks.admin.notifications.base()}/automatiques`,
            autoNotifConfig: () => `${routerLinks.admin.notifications.base()}/configuration-notification-automatique`,
        },
    },
    referential: {
        base: "/referentiels",
        global: {
            base: () => `${routerLinks.referential.base}/global`,
            species: () => `${routerLinks.referential.global.base()}/especes`,
            dpeDirectory: () => `${routerLinks.referential.global.base()}/dpes`,
            famDamAgencies: () => `${routerLinks.referential.global.base()}/groupes-fam-dam`,
            agriMinistryGeoUnit: () => `${routerLinks.referential.global.base()}/services-administratifs`,
            workshops: () => `${routerLinks.referential.global.base()}/ateliers`,
            ovvt: () => `${routerLinks.referential.global.base()}/ovvt`,
        },
        dashboard: () => `${routerLinks.referential.base}/tableau-de-bord`,
        delivery: {
            base: () => `${routerLinks.referential.base}/cession`,
            typeIdentifiant: () => `${routerLinks.referential.delivery.base()}/type-identifiant`,
            drugs: () => `${routerLinks.referential.delivery.base()}/medicaments`,
            famDam: () => `${routerLinks.referential.delivery.base()}/fam-dam`,
            drugTransferOrganization: () => `${routerLinks.referential.delivery.base()}/souches-logicielles`,
            pharmacies: () => `${routerLinks.referential.delivery.base()}/pharmacies`,
        },
        training: {
            base: () => `${routerLinks.referential.base}/formation`,
            formats: () => `${routerLinks.referential.training.base()}/formats`,
            types: () => `${routerLinks.referential.training.base()}/types`,
            organizations: () => `${routerLinks.referential.training.base()}/organismes`,
        },
    },
    // TODO maybe rework the routing to get rid of the base
    continuousTraining: {
        base: "/formations-continues",
        trainings: () => `${routerLinks.continuousTraining.base}/formations`,
        catalog: () => `${routerLinks.continuousTraining.base}/catalogue`,
    },
    healthAccreditationTraining: {
        base: "/formations-habilitation-sanitaire",
        catalog: () => `${routerLinks.healthAccreditationTraining.base}/catalogue`,
        sessions: {
            base: () => `${routerLinks.healthAccreditationTraining.base}/sessions`,
            view: () => `${routerLinks.healthAccreditationTraining.sessions.base()}/consultation`,
            form: () => `${routerLinks.healthAccreditationTraining.sessions.base()}/saisie`,
        },
    },
    delivery: {
        base: "/cessions-antimicrobiens",
        dashboard: {
            base: () => `${routerLinks.delivery.base}/dashboard`,
            trackingLogs: () => `${routerLinks.delivery.dashboard.base()}/suivi-imports`,
            deliveries: () => `${routerLinks.delivery.dashboard.base()}/suivi-cessions`,
            prescriptions: () => `${routerLinks.delivery.dashboard.base()}/suivi-ordonnances`,
        },
        manualDelivery: {
            base: () => `${routerLinks.delivery.base}/saisie-manuelle`,
            form: () => `${routerLinks.delivery.manualDelivery.base()}/formulaire`,
            view: () => `${routerLinks.delivery.manualDelivery.base()}/consultation`,
        },
        powerBi: {
            base: () => `${routerLinks.delivery.base}/power-bi`,
            followUp: () => `${routerLinks.delivery.powerBi.base()}/suivi`,
            indicators: () => `${routerLinks.delivery.powerBi.base()}/indicateurs`,
            dashBoard: () => `${routerLinks.delivery.powerBi.base()}/dashboard`,
        },
    },
    vaccinationCampaign: {
        base: "/campagnes-vaccination",
        vaccine: {
            base: () => `${routerLinks.vaccinationCampaign.base}/vaccins`,
            order: () => `${routerLinks.vaccinationCampaign.vaccine.base()}/commander`,
            view: () => `${routerLinks.vaccinationCampaign.vaccine.base()}/suivre-mes-commandes`,
            scrappingView: () => `${routerLinks.vaccinationCampaign.vaccine.base()}/mises-au-rebut`,
            scrappingDeclare: () => `${routerLinks.vaccinationCampaign.vaccine.base()}/declarer-mise-au-rebut`,
        },
    },
    iahp: {
        base: "/vaccination-iahp",
        farm: {
            base: () => `${routerLinks.iahp.base}/elevages`,
            declareRelation: () => `${routerLinks.iahp.farm.base()}/declarer-relation`,
            view: () => `${routerLinks.iahp.farm.base()}/consultation`,
        },
        vaccinationSite: {
            base: () => `${routerLinks.iahp.base}/chantiers`,
            view: () => `${routerLinks.iahp.vaccinationSite.base()}/suivre-mes-chantiers`,
            editForm: () => `${routerLinks.iahp.vaccinationSite.base()}/:id/saisie-chantier`,
            form: () => `${routerLinks.iahp.vaccinationSite.base()}/saisie-chantier`,
            vaccinationIntervention: {
                base: () => `${routerLinks.iahp.vaccinationSite.base()}/interventions-vaccination`,
                form: () => `${routerLinks.iahp.vaccinationSite.base()}/:vaccinationSiteId/saisie-intervention`,
                editForm: () =>
                    `${routerLinks.iahp.vaccinationSite.base()}/:vaccinationSiteId/saisie-intervention/:interventionId`,
            },
        },
        monitoringIntervention: {
            base: () => `${routerLinks.iahp.base}/interventions-surveillance`,
            view: () => `${routerLinks.iahp.monitoringIntervention.base()}/suivre-mes-interventions`,
            form: () => `${routerLinks.iahp.monitoringIntervention.base()}/saisie-intervention`,
        },
        iahpPowerBi: () => `${routerLinks.iahp.base}/suivi-iahp`,
        exports: () => `${routerLinks.iahp.base}/exports`,
        traceabilityIahp: {
            base: () => `${routerLinks.iahp.base}/tracabilite-iahp`,
            monitoringIntervention: () => `${routerLinks.iahp.traceabilityIahp.base()}/interventions-surveillance`,
            scrapping: () => `${routerLinks.iahp.traceabilityIahp.base()}/mise-au-rebut`,
            vaccinationSite: () => `${routerLinks.iahp.traceabilityIahp.base()}/chantiers`,
        },
        billing: {
            base: () => `${routerLinks.iahp.base}/facturation-iahp`,
            management: () => `${routerLinks.iahp.billing.base()}/referentiels-facturation`,
            vaccinationSites: () => `${routerLinks.iahp.billing.base()}/facturation-chantiers`,
            memories: () => `${routerLinks.iahp.billing.base()}/memoires-de-paiement`,
            vaccinationSitesOutsideCalypso: () =>
                `${routerLinks.iahp.billing.base()}/facturation-chantiers-hors-calypso`,
        },
    },
    parameters: {
        base: "/parametres",
        dpePreferences: () => `${routerLinks.parameters.base}/preference-dpe`,
        userPreferences: () => `${routerLinks.parameters.base}/mes-preferences`,
    },
    sanitaryFollowUp: {
        base: "/suivi-sanitaire",
        butcherySector: {
            base: () => `${routerLinks.sanitaryFollowUp.base}/filiere-bouchere`,
            search: () => `${routerLinks.sanitaryFollowUp.butcherySector.base()}/recherche-equins`,
            exclusion: () => `${routerLinks.sanitaryFollowUp.butcherySector.base()}/exclusion`,
        },
    },
    healthReporting: {
        base: "/signalements-maltraitance",
        animalAbuse: {
            base: () => `${routerLinks.healthReporting.base}/maltraitance-animale`,
            view: () => `${routerLinks.healthReporting.animalAbuse.base()}/suivre-mes-signalements`,
            form: () => `${routerLinks.healthReporting.animalAbuse.base()}/saisie-signalement`,
        },
    },
};
