// === NPM
import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Catalog } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as Show } from "@/assets/icons/shared/show.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import StyledChip from "@/components/styled/StyledChip";
import { HttpStatus } from "@/interfaces/global";
import { routerLinks } from "@/routers/RouterConstant";
import HealthReportingService from "@/services/HealthReportingService";
import LocalStorageService from "@/services/LocalStorageService";
import { IAttachedFile } from "../../interface";

type AttachedFilesAccordeonProps =
    | {
          comment: string;
          attachments: IAttachedFile[];
          files?: never;
          healthReportingId: string;
      }
    | {
          comment: string;
          attachments?: never;
          files: File[];
          healthReportingId?: never;
      };

export default function AttachedFilesAccordeon({
    healthReportingId,
    attachments,
    comment,
    files,
}: Readonly<AttachedFilesAccordeonProps>) {
    const onFilePreview = async (fileUuid: string) => {
        const file: File | null = await getHealthReportingFile(fileUuid);
        if (!file) return;
        openPreview(file);
    };

    const openPreview = (file: File) => {
        const title = file.name;
        const url = URL.createObjectURL(file);
        LocalStorageService.setLocaleStorageItem(title, url);
        LocalStorageService.setLocaleStorageItem(`${title}Type`, file.type);
        window.open(routerLinks.filePreview.dynamicPath(title), title);
    };

    const getHealthReportingFile = async (fileUuid: string) => {
        const res = await HealthReportingService.getHealthReportingFile(healthReportingId, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = new Blob([res.data], { type: res.headers["content-type"] });
            const fileName = res.headers["content-disposition"].split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers["content-type"],
            });
            return newFile;
        }
        return null;
    };

    const renderFiles = () =>
        files.map((file: File, index) => (
            <Grid item xs={6} md={4} key={index}>
                <StyledChip
                    key={index}
                    label={file.name}
                    onDelete={() => openPreview(file)}
                    deleteIcon={<Show />}
                    icon={<Catalog style={{ color: "inherit" }} />}
                />
            </Grid>
        ));

    const renderAttachments = () =>
        attachments.map((attachment: IAttachedFile) => (
            <Grid item xs={6} md={4} key={attachment.uuid}>
                <StyledChip
                    label={attachment.filename}
                    onDelete={() => onFilePreview(attachment.uuid)}
                    deleteIcon={<Show />}
                    icon={<Catalog style={{ color: "inherit" }} />}
                />
            </Grid>
        ));

    return (
        <GenericAccordion title="III. Commentaires et pièces jointes" defaultExpanded>
            <Stack spacing={2}>
                {comment && <Typography sx={{ whiteSpace: "pre-line" }}>{comment}</Typography>}

                <Grid container direction="row" spacing={1} sx={{ marginTop: 2 }}>
                    {attachments && renderAttachments()}
                    {files && renderFiles()}
                </Grid>
            </Stack>
        </GenericAccordion>
    );
}
