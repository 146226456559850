// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import { IHumanDrug } from "@/components/AntimicrobialDisposal/ManualDelivery/interface";
import { IDrugTransferOrganization } from "@/components/Referentials/containers/Delivery/interface";
import { IUse } from "@/components/Referentials/containers/Global/containers/SpeciesCategory/interface";
import { HttpMethod, IReferentialSynchro, Loader } from "@/interfaces/global";
import {
    ICity,
    IContinuousTrainingOrganization,
    IDepartment,
    IDrug,
    IReferential,
    IRegion,
    ISpecies,
    ISpeciesDetails,
    ITypeIdentifiant,
    TypeIdentifiantUse,
} from "@/interfaces/referential";
import { formatParams, objToQueryParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class ReferentialService {
    // SPECIES

    async getSpecies(payload: {
        page?: number;
        size?: number;
        sorts?: string[];
        use?: string[];
        label?: string;
        code?: string;
        parentUuid?: string;
    }): Promise<AxiosResponse<ISpeciesDetails[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.species.species(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getUses(): Promise<AxiosResponse<IUse[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.species.uses(),
            method: HttpMethod.GET,
        });
    }

    async postSpecies(data: ISpecies): Promise<AxiosResponse<ISpecies>> {
        return HttpService.fetch({
            url: endpoints.referentialService.species.species(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putSpecies(data: ISpecies, specieUuid: string): Promise<AxiosResponse<ISpecies>> {
        return HttpService.fetch({
            url: endpoints.referentialService.species.speciesUuid(specieUuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }
    async deleteSpecies(specieUuid: string): Promise<AxiosResponse<ISpecies>> {
        return HttpService.fetch({
            url: endpoints.referentialService.species.speciesUuid(specieUuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getSpeciesSynchro(): Promise<AxiosResponse<IReferentialSynchro>> {
        return HttpService.fetch({
            url: endpoints.referentialService.species.sync(),
            method: HttpMethod.GET,
        });
    }

    async putSpeciesSynchro(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.species.sync(),
            method: HttpMethod.PUT,
            loader: Loader.REQUEST,
        });
    }

    // ORGANIZATIONS

    async getContinuousTrainingOrganizationReferential(payload: {
        page?: number;
        size?: number;
        label?: string;
        externalId?: string;
        email?: string;
        archived?: string[];
        teletransmissionDate?: string[];
        approvalStartDate?: string[];
        approvalEndDate?: string[];
        teletransmissionActive?: string;
        teletransmissionInactive?: string;
    }): Promise<AxiosResponse<IContinuousTrainingOrganization[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.continuousTrainingOrganizationReferential(),
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async postContinuousTrainingOrganization(
        data: IContinuousTrainingOrganization
    ): Promise<AxiosResponse<IContinuousTrainingOrganization>> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.continuousTrainingOrganizationReferential(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putContinuousTrainingOrganization(
        uuid: string,
        data: IContinuousTrainingOrganization
    ): Promise<AxiosResponse<IContinuousTrainingOrganization>> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.continuousTrainingOrganizationUuid(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async archiveContinuousTrainingOrganization(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.continuousTrainingOrganizationArchive(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async restoreContinuousTrainingOrganization(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.continuousTrainingOrganizationRestore(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async getDrugTransferOrganizationReferential(): Promise<AxiosResponse<IDrugTransferOrganization[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.drugTransferOrganizationReferential(),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async postDrugTransferOrganization(
        data: IDrugTransferOrganization
    ): Promise<AxiosResponse<IDrugTransferOrganization>> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.drugTransferOrganizationReferential(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteDrugTransferOrganization(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.drugTransferOrganizationReferentialUuid(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async putDrugTransferOrganization(data: IDrugTransferOrganization, uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.organizations.drugTransferOrganizationReferentialUuid(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }
    // DRUG

    async postHumanDrug(data: IHumanDrug): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.humanDrugs.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getTypeIdentifiant(use?: TypeIdentifiantUse): Promise<AxiosResponse<ITypeIdentifiant[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.drugs.typeIdentifiant(),
            method: HttpMethod.GET,
            params: formatParams({ use }),
            loader: Loader.TABLE,
        });
    }

    async getProductStatus(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.drugs.productStatus(),
        });
    }
    async getDrugSynchro(): Promise<AxiosResponse<IReferentialSynchro>> {
        return HttpService.fetch({
            url: endpoints.referentialService.drugs.sync(),
            method: HttpMethod.GET,
        });
    }

    async putDrugSynchro(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.drugs.sync(),
            method: HttpMethod.PUT,
            loader: Loader.REQUEST,
        });
    }

    async getDrugs(payload: any): Promise<AxiosResponse<IDrug[]>> {
        const query = objToQueryParams(payload);
        return HttpService.fetch({
            url: endpoints.referentialService.drugs.drugs(query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    // LOCALIZATION

    async getCities(payload: any): Promise<AxiosResponse<ICity[]>> {
        const query = objToQueryParams(payload);
        return HttpService.fetch({
            url: endpoints.referentialService.localization.cities(query),
            method: HttpMethod.GET,
        });
    }

    async getDepartments(): Promise<AxiosResponse<IDepartment[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.localization.departments(),
            method: HttpMethod.GET,
        });
    }

    async getRegions(): Promise<AxiosResponse<IRegion[]>> {
        return HttpService.fetch({
            url: endpoints.referentialService.localization.regions(),
            method: HttpMethod.GET,
        });
    }

    async getLocalizationSynchro(): Promise<AxiosResponse<IReferentialSynchro>> {
        return HttpService.fetch({
            url: endpoints.referentialService.localization.sync(),
            method: HttpMethod.GET,
        });
    }

    async putLocalizationSynchro(): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.referentialService.localization.sync(),
            method: HttpMethod.PUT,
            loader: Loader.REQUEST,
        });
    }

    // settings

    async getDeliveryImportTemplateDate(): Promise<AxiosResponse<string>> {
        return HttpService.fetch({
            url: endpoints.referentialService.settings.deliveryImportTemplateDate(),
            method: HttpMethod.GET,
        });
    }
}

export default new ReferentialService();
