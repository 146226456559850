// === NPM
import React, { useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import {
    ActionColumnConfig,
    IDefaultTraceabilityFilters,
    InfoColumnsConfig,
    TraceabilityActionType,
} from "@/interfaces/traceability";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import TraceabilityService from "@/services/TraceabilityService";
import { IOvvtTraceability } from "../../interface";
import OvvtTraceabilityDetails from "./containers/OvvtTraceabilityDetails";

export default function OvvtTraceability() {
    const [organizations, setOrganizations] = useState<IOvvtTraceability[]>([]);
    const [inputFilters, setInputFilters] = useState<IDefaultTraceabilityFilters>({
        date: [null, null],
        authorId: "",
        action: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }]);
    const [traceabilityActions, setTraceabilityActions] = useState<IReferential[]>([]);
    const [selectedUuid, setSelectedUuid] = useState<string>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getOrganizationsTraceability();
    }, [pagination, sortModel]);

    useEffect(() => {
        getTraceabilityActions();
    }, []);

    const getOrganizationsTraceability = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await TraceabilityService.getTraceabilityOvvt(payload);
        if (res.status === HttpStatus.OK) {
            setOrganizations(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const getTraceabilityActions = async () => {
        const res = await TraceabilityService.getTraceabilityActions(TraceabilityActionType.OvvtOrganization);
        if (res.status === HttpStatus.OK) {
            setTraceabilityActions(res.data);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns: GridColDef[] = [
        ...InfoColumnsConfig(),
        {
            field: "authorFirstname",
            headerName: "Prénom",
            flex: 1,
        },
        {
            field: "authorLastname",
            headerName: "Nom",
            flex: 1,
        },
        ActionColumnConfig(traceabilityActions),
    ];

    const filterConfigurations: FilterConfigurations<IDefaultTraceabilityFilters> = {
        date: { type: FilterType.DATEPICKER, label: "Date" },
        authorId: { type: FilterType.INPUT, label: "Auteur" },
        action: { type: FilterType.SELECT, label: "Action", values: traceabilityActions },
    };

    return (
        <Stack width="100%" spacing={2}>
            <Typography variant="h4">Traçabilité des OVVT</Typography>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            date: [null, null],
                            authorId: "",
                            action: [],
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={organizations}
                        columns={columns}
                        getRowId={(row) => row.uuid}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        autoHeight
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={["asc", "desc"]}
                        filterMode="server"
                        sortingMode="server"
                        paginationMode="server"
                        rowCount={rowCount}
                        onRowClick={(params: GridRowParams) => setSelectedUuid(params.row.uuid)}
                    />
                </CardContent>
            </Card>
            <OvvtTraceabilityDetails traceabilityUuid={selectedUuid} traceabilityActions={traceabilityActions} />
        </Stack>
    );
}
