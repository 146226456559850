// === NPM
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, TextField, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericCard from "@/components/generics/layout/GenericCard";
import { InterventionCorrectionType } from "@/interfaces/vaccination";
import { defaultTextLimit } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { stringRequired } from "@/resources/FormUtils";

interface CorrectionDialogProps {
    selectedInterventionId: string;
    correctionType: InterventionCorrectionType;
    onClose: () => void;
    onValid: (motif: string) => void;
}

const schema = z.object({
    reason: stringRequired().max(defaultTextLimit, "Le motif ne doit pas dépasser 2000 caractères"),
});

type Schema = z.infer<typeof schema>;

export default function CorrectionDialog({
    selectedInterventionId,
    correctionType,
    onClose,
    onValid,
}: Readonly<CorrectionDialogProps>) {
    const {
        formState: { errors },
        handleSubmit,
        register,
        watch,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
        defaultValues: {
            reason: null,
        },
    });

    const reason = watch("reason");

    const renderContent = () => (
        <Stack spacing={2}>
            <Typography variant="h5">Intervention n°{selectedInterventionId}</Typography>
            <IconBannerText
                icon={<Warning />}
                message={
                    correctionType === InterventionCorrectionType.DELETE
                        ? "Attention : la suppression d’une intervention de vaccination est définitive et supprime également le certificat de vaccination dans Calypso. Le certificat déjà édité et transmis ne sera plus valable."
                        : "Attention : la fonctionnalité de correction ne modifie pas le certificat de vaccination déjà généré."
                }
                color={statusColor.warningText}
                backgroundColor={statusColor.warningBackground}
            />
            <GenericCard>
                <Stack spacing={2}>
                    <TextField
                        {...register("reason")}
                        label="Motif de correction de l'intervention"
                        error={!!errors.reason}
                        required
                        fullWidth
                        rows={3}
                        helperText={errors.reason?.message ?? `${reason?.length ?? 0}/${defaultTextLimit}`}
                        inputProps={{
                            maxLength: { defaultTextLimit },
                        }}
                    />
                </Stack>
            </GenericCard>
        </Stack>
    );

    return (
        <GenericDialog
            title={`Correction de l'intervention de vaccination : ${
                correctionType === InterventionCorrectionType.DELETE ? "Suppression" : "Modification"
            }`}
            onClose={onClose}
            maxWidth="xl"
            renderContent={renderContent}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    onSubmit={handleSubmit((data) => onValid(data.reason))}
                    validLabel="Continuer"
                />
            )}
        />
    );
}
