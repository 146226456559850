export interface IAccount {
    uuid: string;
    label: string;
    endDate: string;
    type: string;
    uniqueKey: boolean;
    email: string;
}

export interface ICreateAccount {
    organizationUuid: string;
    email: string;
    endDate: string;
    type?: string;
}

export interface IAccountWithKeys extends IAccount {
    apiKeysNumber: number;
    creationDate: string;
}

export interface IAlert {
    uuid: string;
    accountLabel: string;
    accountType: string;
    apiKeyId: string;
    apiKeyType: string;
    treated: boolean;
    alertDate: string;
    uniqueKey: boolean;
}

export interface IApiKey {
    uuid: string;
    accountType: string;
    accountLabel: string;
    label: string;
    type: string;
    externalId: string;
    endDate: string;
    creationDate: string;
    expirationDate: string;
}

export type AdministrationApiKeysOutletContext = {
    apiKeyProfilesReferential: IProfile[];
    periodicitiesReferential: IPeriodicity[];
    typesReferential: IApiKeyType[];
    accountTypesReferential: IAccountType[];
    accounts: IAccountWithKeys[];
    getAccounts: any;
    setSelectedAccount: any;
    setOpenConfirmDeleteAccount: any;
    setOpenViewAccountInformation: any;
    setOpenModifyAccountInformation: any;
    updateProfilePeriodicity: (profileKey: string, periodicityKey) => void;
};

export interface IKey {
    uuid: string;
    label: string;
    creationDate: string;
    expirationDate: string;
    endDate: string;
}

export interface IApiKeyType {
    label: string;
}

export interface IDefaultReferential {
    key: string;
    label: string;
}

export interface IProfile extends IDefaultReferential {
    periodicityKey: string;
}

export interface IPeriodicity extends IDefaultReferential {
    days: number;
}

export interface IAccountType extends IDefaultReferential {
    uniqueKey: boolean;
    profileKey: string;
}

export enum Profile {
    DRUG_TRANSFER = "drug_transfer",
    SSO = "sso",
    CONTINUOUS_TRAINING = "continuous_training",
    VACCINATION_INTERVENTION = "vaccination_intervention",
    BNO = "bno",
}

export const drugTransferOrganizationType = [
    {
        label: "VIMS",
        key: "vims",
    },
    {
        label: "PIMS",
        key: "pims",
    },
    {
        label: "FIMS",
        key: "fims",
    },
];
