// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import {
    ICatalog,
    ICreateTraining,
    ITraining,
    ITrainingDpe,
    ITrainingStatus,
    ITrainingUserInformation,
} from "@/components/ContinuousTraining/interface";
import {
    ICatalogHealthAccreditationTrainingFilters,
    IHealthAccreditationCreate,
    ITrainingSessionCreate,
} from "@/components/HealthAccreditationTraining/interface";
import { ArrayOfTwo, HttpMethod, IExportFilename, Loader, SearchParams } from "@/interfaces/global";
import {
    IHealthAccreditationTraining,
    IHealthAccreditationTrainingShort,
    ITrainingReferential,
    ITrainingReferentialArchived,
    ITrainingType,
} from "@/interfaces/training";
import { formatParams, objToQueryParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class TrainingService {
    // REFERENTIALS
    async getTypes(): Promise<AxiosResponse<ITrainingReferential[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.types,
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }
    async postType(data: { label: string }): Promise<AxiosResponse<ITrainingReferential[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.types,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteType(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.type(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async putType(uuid: string, data: { label: string }): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.type(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getStatus(): Promise<AxiosResponse<ITrainingStatus[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.status,
            method: HttpMethod.GET,
        });
    }

    async getFormats(): Promise<AxiosResponse<ITrainingReferentialArchived[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.formats,
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async postFormat(data: { label: string }): Promise<AxiosResponse<ITrainingReferential[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.formats,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteFormat(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.format(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async putFormat(uuid: string, data: { label: string }): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.format(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async archiveFormat(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.archiveFormat(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async restoreFormat(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.restoreFormat(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    // CONTINUOUS-TRAINING

    async getUserTrainingInformations(): Promise<AxiosResponse<ITrainingUserInformation>> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.trainingInformations(),
            method: HttpMethod.GET,
        });
    }

    async getContinuousTrainings(
        payload: SearchParams &
            Partial<{
                title: string;
                typeUuid: string[];
                formatUuid: string[];
                startDate: ArrayOfTwo[];
                ectsPoints: string;
                allTraings: string;
            }>
    ): Promise<AxiosResponse<ITraining[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async postContinuousTraining(data: ICreateTraining, file: File): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        formData.append("training", blob);
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.base,
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async putContinuousTraining(trainingUuid: string, data: ICreateTraining, file: File): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        formData.append("training", blob);
        formData.append("file", file);

        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.training(trainingUuid),
            method: HttpMethod.PUT,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async getContinuousTrainingFile(trainingUuid: string, fileUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.trainingFile(trainingUuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async getContinuousTrainingDpeFile(
        dpeOrdinalNumber: string,
        trainingUuid: string,
        fileUuid: string
    ): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.dpeFile(dpeOrdinalNumber, trainingUuid, fileUuid),
            method: HttpMethod.GET,
            responseType: "blob",
        });
    }

    async deleteTraining(trainingUuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.training(trainingUuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    // CATALOG

    async getContinuousTrainingCatalog(
        payload: SearchParams &
            Partial<{
                title: string;
                organization: string[];
                format: string[];
                type: string[];
                themes: string;
                species: string[];
                startDate: string[];
                departments: string[];
                postalCode: string[];
                municipalities: string[];
            }>
    ): Promise<AxiosResponse<ICatalog[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch({
            url: endpoints.trainingService.catalog.continuousTrainingCatalog(query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async getDpeContinuousTraining(
        dpeOrdinalNumber: string,
        payload: { startDate?: string }
    ): Promise<AxiosResponse<ITrainingDpe[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch({
            url: endpoints.trainingService.continuousTraining.dpes(dpeOrdinalNumber, query),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }

    async exportTrainingCertificates(data: IExportFilename): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.exports.continuousTrainings(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getHealthAccreditationTrainings(
        payload: SearchParams & Partial<ICatalogHealthAccreditationTrainingFilters>
    ): Promise<AxiosResponse<IHealthAccreditationTrainingShort[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getHealthAccreditationTraining(uuid: string): Promise<AxiosResponse<IHealthAccreditationTraining>> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.training(uuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async postHealthAccreditationTraining(data: IHealthAccreditationCreate, files: File[]): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        files?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("training", blob);

        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.base,
            method: HttpMethod.POST,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async putHealthAccreditationTraining(
        uuid: string,
        data: IHealthAccreditationCreate,
        files: File[]
    ): Promise<AxiosResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        files?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("training", blob);

        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.training(uuid),
            method: HttpMethod.PUT,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            loader: Loader.REQUEST,
        });
    }

    async archiveHealthAccreditationTraining(uuid: string): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.training(uuid),
            method: HttpMethod.PATCH,
            loader: Loader.REQUEST,
        });
    }

    async getHealthAccreditationTrainingFile(uuid: string, fileUuid: string): Promise<AxiosResponse<File>> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.trainingFile(uuid, fileUuid),
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationTrainingTypes(): Promise<AxiosResponse<ITrainingType[]>> {
        return HttpService.fetch({
            url: endpoints.trainingService.referentials.healthAccreditationTypes(),
            method: HttpMethod.GET,
        });
    }

    async postHealthAccreditationTrainingSession(data: ITrainingSessionCreate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.sessions(),
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putHealthAccreditationTrainingSession(uuid: string, data: ITrainingSessionCreate): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.trainingService.healthAccreditationTraining.session(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }
}

export default new TrainingService();
