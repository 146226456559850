// === NPM
import { AxiosResponse } from "axios";
// === LOCAL
import { IManualOrder, IOrder, IServipharOrder } from "@/components/VaccinationCampaign/Vaccination/Order/interface";
import {
    IOrderDetails,
    ITrackingOrder,
    TrackingOrderFilters,
} from "@/components/VaccinationCampaign/Vaccination/OrderTracking/interface";
import { IScrappingFilters } from "@/components/VaccinationCampaign/Vaccination/ScrappingTracking/interface";
import {
    IMonitoringIntervention,
    IMonitoringInterventionCorrection,
    IMonitoringInterventionFilters,
} from "@/components/VaccinationIahp/MonitoringIntervention/interface";
import {
    IVaccinationIntervention,
    IVaccinationInterventionCounts,
    IVaccinationInterventionDetails,
    IVaccinationInterventionFilters,
    IVaccinationInterventionForm,
    IVaccinationSiteCorrection,
    IVaccinationSiteFilters,
    IVaccinationSiteForm,
    IVaccinationSiteInfo,
    IVaccinationSiteWorkshop,
    IVaccinationSiteWorkshopInfo,
} from "@/components/VaccinationIahp/VaccinationSite/interface";
import { HttpMethod, IExportFilename, IExportParams, Loader, SearchParams } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import {
    IAudit,
    IMonitoringInterventionDetails,
    IMonitoringInterventionForm,
    InjectionType,
    INonCompliance,
    IScrappingShort,
    IVaccinationSite,
    IVaccinationSiteShort,
    IVaccine,
} from "@/interfaces/vaccination";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class VaccinationService {
    async getVaccines(): Promise<AxiosResponse<IVaccine[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.vaccines(),
            method: HttpMethod.GET,
        });
    }

    async getSectors(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.sectors(),
            method: HttpMethod.GET,
        });
    }

    async getTiers(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.tiers(),
            method: HttpMethod.GET,
        });
    }

    async postOrder(data: IServipharOrder | IManualOrder): Promise<AxiosResponse<IOrder>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.orders.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getOrders(payload: SearchParams & Partial<TrackingOrderFilters>): Promise<AxiosResponse<ITrackingOrder[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.orders.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getOrderDetails(id: string): Promise<AxiosResponse<IOrderDetails>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.orders.order(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async getOrderStatuses(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.orderStatuses(),
            method: HttpMethod.GET,
        });
    }

    async getInterventions(
        dataType: "id",
        payload: SearchParams & {
            id: string;
            injectionType: InjectionType[];
            vaccinationDateBefore?: string;
            speciesUuid?: string;
            subSpeciesUuid?: string;
        }
    ): Promise<AxiosResponse<{ id: string }[]>>;
    async getInterventions(
        dataType: "",
        payload: SearchParams & Partial<IVaccinationInterventionFilters>
    ): Promise<AxiosResponse<IVaccinationIntervention[]>>;
    async getInterventions(
        dataType: "id" | "",
        payload:
            | (SearchParams & Partial<IVaccinationInterventionFilters>)
            | {
                  id: string;
                  injectionType: InjectionType[];
                  vaccinationDateBefore?: string;
                  speciesUuid?: string;
                  subSpeciesUuid?: string;
              }
    ): Promise<AxiosResponse<IVaccinationIntervention[] | { id: string }[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.DATA_TYPE]: dataType,
            },
            loader: Loader.TABLE,
        });
    }

    async getVaccineBatches(
        vaccineGtinCode: string,
        payload: SearchParams & { number?: string; uuid?: string[] }
    ): Promise<AxiosResponse<{ uuid: string; number: string }[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.batches(vaccineGtinCode),
            method: HttpMethod.GET,
            params: formatParams(payload),
        });
    }

    async getHorsePower(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.horsePower(),
            method: HttpMethod.GET,
        });
    }

    async putIntervention(
        id: string,
        data: IVaccinationInterventionForm,
        certificateGeneration: boolean
    ): Promise<AxiosResponse<IVaccinationInterventionForm>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.PUT,
            data: { ...data, certificateGeneration },
            loader: Loader.REQUEST,
        });
    }

    async deleteInterventionCorrection(id: string, reason: string): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.deleteInterventionCorrection(id),
            method: HttpMethod.PATCH,
            data: { reason },
            loader: Loader.REQUEST,
        });
    }

    async patchInterventionCorrection(
        id: string,
        reason: string,
        data: IVaccinationInterventionForm
    ): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.updateInterventionCorrection(id),
            method: HttpMethod.PATCH,
            data: { reason, body: data },
            loader: Loader.REQUEST,
        });
    }

    async deleteIntervention(id: string): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getIntervention(id: string): Promise<AxiosResponse<IVaccinationInterventionDetails>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async patchIntervention(id: string, data: { uuid: string; imepNumber: string }[]) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.intervention(id),
            method: HttpMethod.PATCH,
            data,
        });
    }

    async generateCertificateIntervention(id: string) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.generateInterventionCertificate(id),
            method: HttpMethod.POST,
            loader: Loader.BUTTON,
        });
    }

    async getVaccinationInterventionFile(interventionId: string, fileId: string) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.file(interventionId, fileId),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    /// ---- SCRAPPING

    async getScrappings(payload: SearchParams & Partial<IScrappingFilters>) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async deleteScrapping(uuid: string) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.scrapping(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getScrappingReasons() {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.reasons(),
            method: HttpMethod.GET,
        });
    }

    /// ---- MONITORING INTERVENTIONS

    async getMonitoringInterventions(
        payload: SearchParams & Partial<IMonitoringInterventionFilters>
    ): Promise<AxiosResponse<IMonitoringIntervention[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.base,
            method: HttpMethod.GET,
            params: formatParams(payload),
            loader: Loader.TABLE,
        });
    }

    async getMonitoringIntervention(id: string): Promise<AxiosResponse<IMonitoringInterventionDetails>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.intervention(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async postMonitoringIntervention(data: IMonitoringInterventionForm): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putMonitoringIntervention(id: string, data: IMonitoringInterventionForm): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.intervention(id),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async deleteMonitoringIntervention(id: string): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.intervention(id),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getMonitoringInterventionFile(interventionId: string, fileId: string) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.file(interventionId, fileId),
            method: HttpMethod.GET,
            responseType: "blob",
            loader: Loader.BUTTON,
        });
    }

    async postScrapping(data: IScrappingShort) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putScrapping(uuid: string, data: IScrappingShort) {
        return HttpService.fetch({
            url: endpoints.vaccinationService.scrappings.scrapping(uuid),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }
    async getSerologicalTypes(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.serologicalTypes(),
            method: HttpMethod.GET,
        });
    }

    async getNonFullfilmentReasons(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.nonFullfilmentReasons(),
            method: HttpMethod.GET,
        });
    }

    async getClinicalNonComplianceReasons(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.clinicalNonComplianceReasons(),
            method: HttpMethod.GET,
        });
    }

    async getMonitoringInterventionStatuses(): Promise<AxiosResponse<IReferential[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.referential.monitoringInterventionStatuses(),
            method: HttpMethod.GET,
        });
    }

    async getVaccinationInterventionExport(data: IExportParams): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.vaccinationIntervention(),
            method: HttpMethod.POST,
            data,
        });
    }

    async getMonitoringInterventionExport(data: IExportParams): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.monitoringIntervention(),
            method: HttpMethod.POST,
            data,
        });
    }

    async getRelationExport(data: IExportParams): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.relation(),
            method: HttpMethod.POST,
            data,
        });
    }

    async correctIntervention(id: string, data: IMonitoringInterventionCorrection): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.monitoringInterventions.correction(id),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async exportIahpData(data: IExportFilename): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.exports.base,
            method: HttpMethod.POST,
            data,
        });
    }

    // ---- VACCINATION SITES

    async getVaccinationSites(
        payload: SearchParams & Partial<IVaccinationSiteFilters>
    ): Promise<AxiosResponse<IVaccinationSiteShort[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.base,
            method: HttpMethod.GET,
            loader: Loader.TABLE,
            params: formatParams(payload),
        });
    }

    async postVaccinationSite(data: IVaccinationSiteForm): Promise<AxiosResponse<IVaccinationSite>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.base,
            method: HttpMethod.POST,
            data,
            loader: Loader.REQUEST,
        });
    }

    async putVaccinationSite(id: number, data: IVaccinationSiteForm): Promise<AxiosResponse<IVaccinationSite>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.vaccinationSite(id),
            method: HttpMethod.PUT,
            data,
            loader: Loader.REQUEST,
        });
    }

    async getVaccinationSiteInfos(id: number): Promise<AxiosResponse<IVaccinationSiteInfo>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.infos(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async deleteVaccinationSite(id: number): Promise<AxiosResponse<void>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.vaccinationSite(id),
            method: HttpMethod.DELETE,
            loader: Loader.REQUEST,
        });
    }

    async getVaccinationSite(id: number): Promise<AxiosResponse<IVaccinationSite>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.vaccinationSite(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async patchVaccinationSiteCorrection(id: number, data: IVaccinationSiteCorrection): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.delete(id),
            method: HttpMethod.PATCH,
            data: data,
            loader: Loader.REQUEST,
        });
    }

    async postVaccinationIntervention(
        vaccinationSiteId: number,
        data: IVaccinationInterventionForm,
        certificateGeneration: boolean
    ): Promise<AxiosResponse<IVaccinationInterventionForm>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.vaccinationInterventions(vaccinationSiteId),
            method: HttpMethod.POST,
            data: { ...data, certificateGeneration },
            loader: Loader.REQUEST,
        });
    }

    // ---- AUDIT

    async getAudit(uuid: string): Promise<AxiosResponse<IAudit>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.audits.audit(uuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async deleteAudit(uuid: string): Promise<AxiosResponse<IAudit>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.audits.audit(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.BUTTON,
        });
    }

    async postAudit(vaccinationSiteId: number, audit: IAudit): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.audits(vaccinationSiteId),
            method: HttpMethod.POST,
            data: audit,
            loader: Loader.REQUEST,
        });
    }

    async putAudit(uuid: string, audit: IAudit): Promise<AxiosResponse<IAudit>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.audits.audit(uuid),
            method: HttpMethod.PUT,
            data: audit,
            loader: Loader.REQUEST,
        });
    }

    // ---- NON COMPLIANCE
    async getNonCompliance(uuid: string): Promise<AxiosResponse<INonCompliance>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.nonCompliance.nonCompliance(uuid),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async deleteNonCompliance(uuid: string): Promise<AxiosResponse<INonCompliance>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.nonCompliance.nonCompliance(uuid),
            method: HttpMethod.DELETE,
            loader: Loader.BUTTON,
        });
    }
    async postNonCompliance(vaccinationSiteId: number, nonCompliance: INonCompliance): Promise<AxiosResponse> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.nonCompliances(vaccinationSiteId),
            method: HttpMethod.POST,
            data: nonCompliance,
            loader: Loader.REQUEST,
        });
    }

    async putNonCompliance(uuid: string, nonCompliance: INonCompliance): Promise<AxiosResponse<INonCompliance>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.nonCompliance.nonCompliance(uuid),
            method: HttpMethod.PUT,
            data: nonCompliance,
            loader: Loader.REQUEST,
        });
    }

    async getVaccinationSiteWorkshops(
        farmId: string,
        date: string
    ): Promise<AxiosResponse<IVaccinationSiteWorkshop[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.farms.workshops(farmId),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
            params: formatParams({ date }),
        });
    }

    async getVaccinationSiteWorkshopsInfo(id: number): Promise<AxiosResponse<IVaccinationSiteWorkshopInfo[]>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationSites.workshopsInfo(id),
            method: HttpMethod.GET,
            loader: Loader.BUTTON,
        });
    }

    async getVaccinationInterventionCounts(): Promise<AxiosResponse<IVaccinationInterventionCounts>> {
        return HttpService.fetch({
            url: endpoints.vaccinationService.vaccinationInterventions.counts(),
            method: HttpMethod.GET,
            loader: Loader.TABLE,
        });
    }
}

export default new VaccinationService();
