// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import { useProvideGlobal } from "@/context/useGlobalContext";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IOvvtOrganization, IOvvtOrganizationShort, IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import ReferentialService from "@/services/ReferentialService";
import UserService from "@/services/UserService";
import CreateOrUpdateOvvtOrganizationDialog from "./CreateOrUpdateOvvtOrganizationDialog";
import DeleteOvvtOrganizationDialog from "./DeleteOvvtOrganizationDialog";

interface OvvtTableFilters {
    externalId: string;
    name: string;
    regionInseeCode: string[];
}

interface OvvtTableProps {
    setOrganizationUsers: Dispatch<SetStateAction<IOvvtOrganization>>;
}

export default function OvvtTable({ setOrganizationUsers }: Readonly<OvvtTableProps>) {
    const { loadingButton } = useProvideGlobal();

    const [organizations, setOrganizations] = useState<IOvvtOrganization[]>([]);
    const [regions, setRegions] = useState<IReferential[]>([]);
    const [vacantRegions, setVacantRegions] = useState<IReferential[]>([]);
    const [inputFilters, setInputFilters] = useState<OvvtTableFilters>({
        externalId: "",
        name: "",
        regionInseeCode: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "externalId", sort: "asc" }]);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedOvvt, setSelectedOvvt] = useState<IOvvtOrganization>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getOvvtOrganizations();
    }, [pagination, sortModel]);

    useEffect(() => {
        getRegions();
    }, []);

    const getOvvtOrganizations = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await UserService.getOvvtOrganizations(payload);
        if (res.status === HttpStatus.OK) {
            setOrganizations(res.data);
            setRowCount(+res.headers[CALYPSO_HEADERS.TABLE_COUNT]);
        }
    };

    const getRegions = async () => {
        const res = await ReferentialService.getRegions();
        if (res.status === HttpStatus.OK) {
            setRegions(res.data.map((r) => ({ label: `${r.inseeCode} - ${r.name}`, key: r.inseeCode })));
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const createOvvtOrganization = async (data: IOvvtOrganizationShort) => {
        const res = await UserService.createOvvtOrganization(data);
        if (res.status === HttpStatus.CREATED) {
            setOpenCreateDialog(false);
            toast.success("Organisation créée avec succès.");
            getOvvtOrganizations();
        }
    };

    const updateOvvtOrganization = async (data: IOvvtOrganizationShort) => {
        const res = await UserService.updateOvvtOrganization(selectedOvvt.uuid, data);
        if (res.status === HttpStatus.OK) {
            setOpenUpdateDialog(false);
            setSelectedOvvt(null);
            toast.success("Organisation mise à jour avec succès.");
            getOvvtOrganizations();
        }
    };

    const deleteOvvtOrganization = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            setSelectedOvvt(null);
            return;
        }

        const res = await UserService.deleteOvvtOrganization(selectedOvvt.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenDeleteDialog(false);
            setSelectedOvvt(null);
            toast.success("Organisation supprimée avec succès.");
            getOvvtOrganizations();
        }
    };

    const getVacantRegions = async (organization?: IOvvtOrganization) => {
        const res = await UserService.getOvvtVacantRegions();
        if (res.status === HttpStatus.OK) {
            setVacantRegions(
                res.data.map((region) => ({ key: region.inseeCode, label: `${region.inseeCode} - ${region.name}` }))
            );
            organization ? setOpenUpdateDialog(true) : setOpenCreateDialog(true);
        }
    };

    const columns: GridColDef[] = [
        {
            field: "externalId",
            headerName: "Identifiant",
            flex: 0.5,
        },
        {
            field: "name",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "regionInseeCode",
            headerName: "Région",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => params.row.regionName,
        },
        {
            field: "emails",
            headerName: "Mails",
            sortable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <ul>
                    {params.row.emails?.map((email) => (
                        <Typography key={email} variant="body2" component="li">
                            {email}
                        </Typography>
                    ))}
                </ul>
            ),
        },
        {
            ...ActionsColumnProps,
            width: 100,
            renderCell: (params: GridRenderCellParams) => (
                <Box display="flex" width="100%" justifyContent="center">
                    <EditAction
                        title="Modifier"
                        onClick={() => {
                            setSelectedOvvt(params.row);
                            getVacantRegions(params.row);
                        }}
                    />
                    <DeleteAction
                        title="Supprimer"
                        onClick={() => {
                            setSelectedOvvt(params.row);
                            setOpenDeleteDialog(true);
                        }}
                    />
                </Box>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<OvvtTableFilters> = {
        externalId: { type: FilterType.INPUT, label: "Identifiant" },
        name: { type: FilterType.INPUT, label: "Nom" },
        regionInseeCode: {
            type: FilterType.SELECT_AUTOCOMPLETE,
            label: "Région",
            values: regions,
        },
    };

    return (
        <>
            <Typography variant="h4">Organismes Vétérinaire à Vocation Technique</Typography>
            <Box justifyContent="flex-end" display="flex">
                <GenericButton
                    label="Ajouter une organisation"
                    onClick={() => getVacantRegions()}
                    startIcon={<Add />}
                    loading={loadingButton}
                />
            </Box>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            externalId: "",
                            name: "",
                            regionInseeCode: [],
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={organizations}
                        columns={columns}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        getRowId={(row) => row.uuid}
                        autoHeight
                        sortingMode="server"
                        paginationMode="server"
                        sortModel={sortModel}
                        rowCount={rowCount}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                        filterMode="server"
                        onRowClick={(params: GridRowParams) => setOrganizationUsers(params.row)}
                    />
                </CardContent>
            </Card>
            {openCreateDialog && (
                <CreateOrUpdateOvvtOrganizationDialog
                    title="Créer un Organisme Véterinaire à Vocation Technique"
                    onClose={() => setOpenCreateDialog(false)}
                    onValid={createOvvtOrganization}
                    regions={vacantRegions}
                />
            )}

            {openUpdateDialog && (
                <CreateOrUpdateOvvtOrganizationDialog
                    title={`Modifier l'organisme ${selectedOvvt.externalId}`}
                    organization={selectedOvvt}
                    onClose={() => {
                        setOpenUpdateDialog(false);
                        setSelectedOvvt(null);
                    }}
                    onValid={updateOvvtOrganization}
                    regions={[...vacantRegions, regions.find((region) => region.key === selectedOvvt.regionInseeCode)]}
                />
            )}

            {openDeleteDialog && (
                <DeleteOvvtOrganizationDialog
                    organizationId={selectedOvvt.externalId}
                    deletable={selectedOvvt.userCount === 0}
                    onClose={deleteOvvtOrganization}
                />
            )}
        </>
    );
}
