// === NPM
import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Building } from "@/assets/icons/home/building.svg";
import { ReactComponent as Calendar } from "@/assets/icons/home/calendar.svg";
import { ReactComponent as Delivery } from "@/assets/icons/home/delivery.svg";
import { ReactComponent as Geo } from "@/assets/icons/home/geo.svg";
import { ReactComponent as Login } from "@/assets/icons/home/login.svg";
import { ReactComponent as Training } from "@/assets/icons/home/training.svg";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { ImportStatus } from "@/interfaces/drug";
import { HttpStatus } from "@/interfaces/global";
import { UserType } from "@/interfaces/user";
import { colors } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { toLocaleDateFormat } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { IActivity } from "../interface";
import ActivitiesSkeleton from "./ActivitiesSkeleton";

export default function Activities() {
    const auth = useAuth();
    const [activities, setActivities] = useState<IActivity>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getActivities();
    }, []);

    const getActivities = async () => {
        setLoading(true);
        const res = await UserService.getUserActivities();
        if (res.status === HttpStatus.OK) {
            setActivities(res.data);
        }
        setLoading(false);
    };

    const renderLoginActivity = () => (
        <Stack direction="row" spacing={2}>
            {activities?.lastConnexionDate ? (
                <>
                    <Calendar />
                    <Stack justifyContent="center">
                        <Typography variant="secondaryBold">Date de ma dernière connexion</Typography>
                        <Typography variant="h5" sx={{ color: colors.green }}>
                            {toLocaleDateFormat(activities.lastConnexionDate)}
                        </Typography>
                    </Stack>
                </>
            ) : (
                <>
                    <Login />
                    <Stack justifyContent="center">
                        <Typography variant="secondaryBold">Bienvenue dans votre espace Calypso !</Typography>
                    </Stack>
                </>
            )}
        </Stack>
    );

    const renderTrainingActivity = () => (
        <Stack direction="row" spacing={2}>
            <Training />
            <Stack justifyContent="center">
                {activities?.lastContinuousTrainingStartDate ? (
                    <>
                        <Typography variant="secondaryBold">Ma dernière formation date du</Typography>
                        <Typography variant="h5" sx={{ color: colors.purple }}>
                            {toLocaleDateFormat(activities.lastContinuousTrainingStartDate)}
                        </Typography>
                    </>
                ) : (
                    <Typography variant="secondaryBold">Je n'ai suivi aucune formation continue</Typography>
                )}
            </Stack>
        </Stack>
    );

    const renderDrugDeliveryActivity = () => (
        <Stack direction="row" spacing={2}>
            <Delivery />
            <Stack justifyContent="center">
                {activities?.lastDrugDeliveryImportDate ? (
                    <>
                        <Typography variant="secondaryBold">
                            Mes dernières cessions d'antimicrobiens datent du
                        </Typography>
                        <Typography variant="h5" sx={{ color: colors.blueDelivery, fontWeight: 600 }}>
                            {toLocaleDateFormat(activities.lastDrugDeliveryImportDate)} avec le statut{" "}
                            {ImportStatus[activities.lastDrugDeliveryImportStatus]}
                        </Typography>
                    </>
                ) : (
                    <Typography variant="secondaryBold">Je n'ai déclaré aucune cession d'antimicrobien</Typography>
                )}
            </Stack>
        </Stack>
    );

    const renderFamDamActivity = () => (
        <Stack direction="row" spacing={2}>
            <Building />
            <Stack justifyContent="center">
                <Typography variant="secondaryBold">
                    {auth.userInfo.situation === UserSituation.FAM_DAM_ADMIN
                        ? "Je suis administrateur du groupe"
                        : "Je dispose des droits de cession pour le groupe"}
                </Typography>
                <Typography variant="h5" sx={{ color: colors.orange, fontWeight: 600 }}>
                    {activities.agencyName}
                </Typography>
            </Stack>
        </Stack>
    );

    const renderAnmvActivity = () => (
        <Stack direction="row" spacing={2}>
            <Delivery />
            <Stack justifyContent="center">
                {activities?.deliveriesNumber ? (
                    <Typography variant="secondaryBold">
                        Il y a {activities.deliveriesNumber} nouvelle(s) cession(s) d’antimicrobiens déclarée(s) par les
                        FAM/DAM depuis ma dernière connexion
                    </Typography>
                ) : (
                    <Typography variant="secondaryBold">
                        Il n’y a pas de nouvelles cessions d’antimicrobiens déclarées par les FAM/DAM depuis ma dernière
                        connexion
                    </Typography>
                )}
            </Stack>
        </Stack>
    );

    const renderMaActivity = () => {
        const isMaAdmin =
            auth.userInfo.situation === UserSituation.ADMIN_DDPP ||
            auth.userInfo.situation === UserSituation.ADMIN_DGAL ||
            auth.userInfo.situation === UserSituation.ADMIN_DRAAF;

        return (
            <Stack direction="row" spacing={2}>
                <Geo />
                <Stack justifyContent="center">
                    <Typography variant="secondaryBold">
                        Je suis {isMaAdmin ? "administrateur" : "utilisateur"} de la {auth.userInfo.properties.name}
                    </Typography>
                </Stack>
            </Stack>
        );
    };

    const renderActivities = () => {
        return (
            <Grid container spacing={4} p={2}>
                <Grid item xs={12} md={6} lg={4}>
                    {renderLoginActivity()}
                </Grid>
                {auth.userInfo.type === UserType.VETERINARY &&
                    auth.userInfo.situation !== UserSituation.PUBLIC_SECTOR_VETERINARIAN &&
                    auth.userInfo.situation !== UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA && (
                        <Grid item xs={12} md={6} lg={4}>
                            {renderTrainingActivity()}
                        </Grid>
                    )}
                {(auth.userInfo.type === UserType.VETERINARY || auth.userInfo.type === UserType.FAM_DAM) && (
                    <Grid item xs={12} md={6} lg={4}>
                        {renderDrugDeliveryActivity()}
                    </Grid>
                )}
                {auth.userInfo.type === UserType.FAM_DAM && activities.agencyName && (
                    <Grid item xs={12} md={12} lg={4}>
                        {renderFamDamActivity()}
                    </Grid>
                )}
                {auth.userInfo.type === UserType.ANMV && auth.userInfo.situation === UserSituation.ANMV_MED && (
                    <Grid item xs={12} md={12} lg={6}>
                        {renderAnmvActivity()}
                    </Grid>
                )}
                {(auth.userInfo.type === UserType.DDPP ||
                    auth.userInfo.type === UserType.DGAL ||
                    auth.userInfo.type === UserType.DRAAF) && (
                    <Grid item xs={12} md={12} lg={4}>
                        {renderMaActivity()}
                    </Grid>
                )}
            </Grid>
        );
    };

    return (
        <Box
            display="flex"
            sx={{ flexGrow: 1, p: 2, backgroundColor: colors.background, borderRadius: 5 }}
            id="home-activities"
        >
            <Stack spacing={2} width="100%">
                <Typography variant="h4">Mon activité</Typography>
                <Card>
                    <StyledCardContent>{loading ? <ActivitiesSkeleton /> : renderActivities()}</StyledCardContent>
                </Card>
            </Stack>
        </Box>
    );
}
