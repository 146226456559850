// === NPM
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { HttpStatus } from "@/interfaces/global";
import { IVaccinationSite, VaccinationInterventionType, VaccineBatch } from "@/interfaces/vaccination";
import { statusColor } from "@/resources/CssConstant";
import { getEnumKeyByValue } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import AnimalAccordion from "../../containers/AnimalAccordion";
import InterventionAccordion from "../../containers/InterventionAccordion";
import { useFormIntervention } from "../../useFormIntervention";

interface RecapDialogProps {
    onClose: () => void;
    onValid: (certificateGeneration: boolean) => void;
    vaccinationSite: IVaccinationSite;
    vaccineBatches: VaccineBatch[];
    diluentBatches: VaccineBatch[];
}

export default function RecapDialog({
    onClose,
    onValid,
    vaccinationSite,
    vaccineBatches,
    diluentBatches,
}: Readonly<RecapDialogProps>) {
    const { loadingRequest } = useProvideGlobal();
    const location = useLocation();
    const navigate = useNavigate();

    const [generateCertificat, setGenerateCertificat] = useState<boolean>(false);

    const { form } = useFormIntervention();

    const handleCorrection = async () => {
        const res = await VaccinationService.patchInterventionCorrection(form.id, location.state?.reason, form);
        if (res.status === HttpStatus.NO_CONTENT) {
            onClose();
            navigate(routerLinks.iahp.vaccinationSite.view());
            toast.success("L'intervention a bien été modifiée");
        }
    };

    const renderCertificat = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Stack spacing={2}>
                    <IconBannerText
                        message={`Cocher la case suivante vous permet de générer un certificat de vaccination. Une fois le certificat généré, vous ne pourrez plus modifier les données de cette intervention.`}
                        color={statusColor.warningText}
                        backgroundColor={statusColor.warningBackground}
                        icon={<Warning />}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={generateCertificat}
                                onChange={(e) => setGenerateCertificat(e.target.checked)}
                            />
                        }
                        label="Je souhaite générer un certificat de vaccination"
                    />
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Stack spacing={2} width="100%">
            <Typography variant="h5" sx={{ mb: 2 }}>
                Intervention de vaccination{" "}
                {form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY)
                    ? "au couvoir"
                    : "en élevage"}
            </Typography>
            <InterventionAccordion
                vaccinationSiteInformation={vaccinationSite}
                workshopId={form.generalInformation.workshopId}
                complianceType={form.generalInformation.complianceType}
                complianceComment={form.generalInformation.complianceComment}
            />
            <AnimalAccordion
                animalInformation={form.animalInformation}
                vaccineBatches={vaccineBatches}
                diluentBatches={diluentBatches}
                vaccinationDate={vaccinationSite.date}
                injectionType={form.animalInformation.injectionType}
                animalBatches={form.animalBatches}
            />
            {!form.certificateGeneration && (
                <>
                    <Typography variant="h5">Certificat de vaccination</Typography>
                    {renderCertificat()}
                </>
            )}
        </Stack>
    );

    const displayActionButton = () => (
        <>
            <SecondaryButton onClick={onClose} variant="outlined" disabled={loadingRequest}>
                Retour au formulaire
            </SecondaryButton>
            {location.state?.reason ? (
                <GenericButton
                    onClick={handleCorrection}
                    color="primary"
                    label="Enregistrer l'intervention de vaccination"
                    loading={loadingRequest}
                />
            ) : (
                <>
                    <GenericButton
                        onClick={() => onValid(false)}
                        color="primary"
                        label="Enregistrer l'intervention de vaccination"
                        disabled={generateCertificat}
                        loading={loadingRequest}
                    />
                    {!form.certificateGeneration && (
                        <GenericButton
                            onClick={() => onValid(true)}
                            color="primary"
                            label="Valider et générer le certificat"
                            disabled={!generateCertificat}
                            loading={loadingRequest}
                        />
                    )}
                </>
            )}
        </>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Récapitulatif de l'intervention de vaccination`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={displayActionButton}
        />
    );
}
