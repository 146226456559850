// === NPM
import React, { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Autocomplete, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { InjectionType, VaccinationInterventionType, VaccineBatch, VaccineType } from "@/interfaces/vaccination";
import { defaultTextLimit } from "@/resources/AppConstant";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { convertEnumToKeyLabelObject, getEnumKeyByValue, typedObjectKeys } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";
import { IVaccinationInterventionAnimalInformation, VaccinationSiteOutletContext } from "../../interface";
import { useFormIntervention } from "../../useFormIntervention";

interface AnimalInformationProps {
    formRef: RefObject<HTMLButtonElement>;
    onValid: () => void;
    selectedVaccineBatches: VaccineBatch[];
    setSelectedVaccineBatches: Dispatch<SetStateAction<VaccineBatch[]>>;
    selectedDiluentBatches: VaccineBatch[];
    setSelectedDiluentBatches: Dispatch<SetStateAction<VaccineBatch[]>>;
}

export default function AnimalInformation({
    formRef,
    onValid,
    selectedVaccineBatches,
    selectedDiluentBatches,
    setSelectedDiluentBatches,
    setSelectedVaccineBatches,
}: Readonly<AnimalInformationProps>) {
    const { vaccines, species, subSpecies, sectors, tiers, diluents } =
        useOutletContext<VaccinationSiteOutletContext>();

    const [firstIntervention, ...others] = typedObjectKeys(InjectionType);

    const { setForm, form } = useFormIntervention();

    const animalInformationSchema = z.object({
        injectionType: z.enum([firstIntervention, ...others], {
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        }),
        vaccineBatchUuids: z.array(z.string()).min(1, FORM_TEXT.required).max(10, "Le nombre de lots est limité à 10"),
        prescribedDoseCount: z.coerce
            .number({
                required_error: FORM_TEXT.required,
                invalid_type_error: "Le nombre de flacons prescrits doit être un nombre positif entier",
            })
            .positive("Le nombre de flacons prescrits doit être un nombre positif entier")
            .multipleOf(0.001, { message: "Le nombre de flacons prescrits doit être un multiple de 0.001" }),
        comment: z.string().trim().max(defaultTextLimit).optional().nullable(),
        sector: stringRequired(),
        tier: stringRequired(),
    });

    const vaccineSchema = z
        .object({
            vaccineGtinCode: stringRequired(),
            diluentGtinCode: z.string().optional().nullable(),
            diluentBatchUuids: z.array(z.string()).optional().nullable(),
        })
        .superRefine((data, ctx) => {
            const vaccine = vaccines.find((vaccine) => vaccine.gtinCode === data.vaccineGtinCode);
            if (vaccine.type === VaccineType.VACCINE_WITH_DILUENT) {
                if (!data.diluentGtinCode) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["diluentGtinCode"],
                    });
                }
                if (data.diluentBatchUuids.length === 0) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["diluentBatchUuids"],
                    });
                }
            }
        });

    const doseSchema = z
        .object({
            animalCount: z.coerce
                .number({
                    required_error: FORM_TEXT.required,
                    invalid_type_error: "Le nombre d'animaux doit être un nombre positif entier",
                })
                .int("Le nombre d'animaux doit être un nombre positif entier")
                .positive("Le nombre d'animaux doit être un nombre positif entier"),
            doseCount: z.coerce
                .number({
                    required_error: FORM_TEXT.required,
                    invalid_type_error: "Le nombre de doses administrées doit être un nombre positif entier",
                })
                .int("Le nombre de doses administrées doit être un nombre positif entier")
                .positive("Le nombre de doses administrées doit être un nombre positif entier"),
        })
        .refine((data) => data.doseCount >= data.animalCount, {
            message: "Le nombre de doses administrées doit être supérieur au nombre d'animaux vaccinés",
            path: ["doseCount"],
        });

    const speciesSchema = z
        .object({
            speciesUuid: stringRequired(),
            subSpeciesUuid: z.string().nullable().optional(),
        })
        .superRefine((data, ctx) => {
            if (species.find((specie) => specie.uuid === data.speciesUuid)?.subSpeciesCount && !data.subSpeciesUuid) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["subSpeciesUuid"],
                });
            }
        });
    const schema = animalInformationSchema.and(speciesSchema).and(doseSchema).and(vaccineSchema);

    const isHatchery =
        form.type === getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.HATCHERY);
    type FormSchema = z.infer<typeof schema>;

    const initInjectionType = () => {
        if (form.animalInformation.injectionType) {
            return form.animalInformation.injectionType;
        } else if (isHatchery) {
            return getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE);
        } else {
            return null;
        }
    };

    const {
        formState: { errors },
        handleSubmit,
        watch,
        register,
        control,
        getValues,
        resetField,
        setValue,
    } = useForm<FormSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            ...form.animalInformation,
            injectionType: initInjectionType(),
        },
    });

    const [vaccineBatchSearch, setVaccineBatchSearch] = useState<string>("");
    const [vaccineBatches, setVaccineBatches] = useState<VaccineBatch[]>([]);
    const [diluentBatchSearch, setDiluantBatchSearch] = useState<string>("");
    const [diluentBatches, setDiluentBatches] = useState<VaccineBatch[]>([]);

    const speciesUuid = watch("speciesUuid");
    const vaccineGtinCode = watch("vaccineGtinCode");
    const comment = watch("comment");
    const vaccine = vaccines.find((vaccine) => vaccine.gtinCode === vaccineGtinCode);
    const hasSubSpecies = !!species.find((specie) => specie.uuid === speciesUuid)?.subSpeciesCount;

    useTimeout(
        () => getBatches(getValues("vaccineGtinCode"), vaccineBatchSearch, setVaccineBatches),
        [vaccineBatchSearch]
    );

    useTimeout(
        () => getBatches(getValues("diluentGtinCode"), diluentBatchSearch, setDiluentBatches),
        [diluentBatchSearch]
    );

    useEffect(() => {
        if (form.animalInformation.vaccineBatchUuids.length >= 1) {
            getBatches(
                getValues("vaccineGtinCode"),
                vaccineBatchSearch,
                setVaccineBatches,
                form.animalInformation.vaccineBatchUuids,
                setSelectedVaccineBatches
            );
        }
        if (form.animalInformation.diluentBatchUuids.length >= 1) {
            getBatches(
                getValues("diluentGtinCode"),
                diluentBatchSearch,
                setDiluentBatches,
                form.animalInformation.diluentBatchUuids,
                setSelectedDiluentBatches
            );
        }
    }, []);

    const getBatches = async (
        gtinCode: string,
        numberSearch: string,
        setter: Dispatch<SetStateAction<VaccineBatch[]>>,
        uuids?: string[],
        setterSelected?: Dispatch<SetStateAction<VaccineBatch[]>>
    ) => {
        const payload = {
            page: 0,
            size: 50,
            number: numberSearch ? numberSearch : undefined,
            uuid: uuids || null,
        };
        const res = await VaccinationService.getVaccineBatches(gtinCode, payload);
        if (res.status === HttpStatus.OK) {
            setter(res.data);
            if (uuids) {
                setterSelected(res.data);
            }
        }
    };

    const resetVaccineData = () => {
        setValue("vaccineBatchUuids", []);
        setVaccineBatches([]);
        setSelectedVaccineBatches([]);
        resetField("diluentGtinCode");
        setValue("diluentBatchUuids", []);
        setDiluentBatches([]);
        setSelectedDiluentBatches([]);
    };

    const renderVaccineInformation = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name="vaccineGtinCode"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value || ""}
                                    label="Vaccin"
                                    onChange={(e) => {
                                        onChange(e);
                                        resetVaccineData();
                                    }}
                                    error={!!error}
                                    required
                                    helperText={error?.message}
                                    options={vaccines}
                                    optionsValue="gtinCode"
                                    optionsLabel="name"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Controller
                            name="injectionType"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    onChange={onChange}
                                    options={convertEnumToKeyLabelObject(InjectionType)}
                                    required
                                    optionsValue="key"
                                    error={!!error}
                                    helperText={error?.message}
                                    label="Injection"
                                    value={value || ""}
                                    disabled={isHatchery || !!form.id}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Autocomplete
                            popupIcon={<KeyboardArrowDown />}
                            multiple
                            options={vaccineBatches}
                            value={selectedVaccineBatches}
                            onChange={(_, newValue) => {
                                setValue(
                                    "vaccineBatchUuids",
                                    newValue.map((batch) => batch.uuid)
                                );
                                setSelectedVaccineBatches(newValue);
                            }}
                            inputValue={vaccineBatchSearch}
                            onInputChange={(_, newInputValue) => {
                                setVaccineBatchSearch(newInputValue);
                            }}
                            filterOptions={(x) => x}
                            getOptionLabel={(option) => option.number}
                            isOptionEqualToValue={(option, value) => {
                                return option.uuid === value.uuid;
                            }}
                            noOptionsText={
                                vaccineBatchSearch
                                    ? "Aucun résultat"
                                    : "Saisissez des caractères pour lancer la recherche"
                            }
                            renderInput={(selected) => (
                                <TextField
                                    variant="outlined"
                                    {...selected}
                                    label="Numéro de lot du vaccin"
                                    required
                                    error={!!errors.vaccineBatchUuids}
                                    helperText={errors.vaccineBatchUuids?.message}
                                />
                            )}
                        />
                    </Grid>
                    {vaccine && vaccine.type === VaccineType.VACCINE_WITH_DILUENT && (
                        <>
                            <Grid item xs={12} md={4}>
                                <Controller
                                    name="diluentGtinCode"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value || ""}
                                            label="Diluant du vaccin"
                                            onChange={(e) => {
                                                onChange(e);
                                                setValue("diluentBatchUuids", []);
                                                setDiluentBatches([]);
                                                setSelectedDiluentBatches([]);
                                            }}
                                            error={!!error}
                                            required
                                            helperText={error?.message}
                                            options={diluents}
                                            optionsValue="gtinCode"
                                            optionsLabel="name"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    popupIcon={<KeyboardArrowDown />}
                                    multiple
                                    options={diluentBatches}
                                    value={selectedDiluentBatches}
                                    onChange={(_, newValue) => {
                                        setValue(
                                            "diluentBatchUuids",
                                            newValue.map((batch) => batch.uuid)
                                        );
                                        setSelectedDiluentBatches(newValue);
                                    }}
                                    inputValue={diluentBatchSearch}
                                    onInputChange={(_, newInputValue) => {
                                        setDiluantBatchSearch(newInputValue);
                                    }}
                                    filterOptions={(x) => x}
                                    getOptionLabel={(option) => option.number}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.uuid === value.uuid;
                                    }}
                                    noOptionsText={
                                        diluentBatchSearch
                                            ? "Aucun résultat"
                                            : "Saisissez des caractères pour lancer la recherche"
                                    }
                                    renderInput={(selected) => (
                                        <TextField
                                            variant="outlined"
                                            {...selected}
                                            label="Numéro de lot du diluant"
                                            required
                                            error={!!errors.diluentBatchUuids}
                                            helperText={errors.diluentBatchUuids?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Nombre d’animaux vaccinés"
                            {...register("animalCount")}
                            required
                            fullWidth
                            error={!!errors.animalCount}
                            helperText={errors?.animalCount?.message}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Nombre de doses administrées"
                            {...register("doseCount")}
                            required
                            fullWidth
                            error={!!errors.doseCount}
                            helperText={errors?.doseCount?.message}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Nombre de flacons prescrits"
                            {...register("prescribedDoseCount")}
                            required
                            fullWidth
                            error={!!errors.prescribedDoseCount}
                            helperText={errors?.prescribedDoseCount?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Commentaire"
                            {...register("comment")}
                            rows={5}
                            multiline
                            fullWidth
                            error={!!errors.comment}
                            helperText={errors.comment?.message ?? `${comment?.length ?? 0}/${defaultTextLimit}`}
                            inputProps={{
                                maxLength: { defaultTextLimit },
                            }}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderAnimalInformation = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} lg={3} xs={12}>
                        <Controller
                            name="speciesUuid"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value || ""}
                                    label="Catégorie d'espèce"
                                    onChange={onChange}
                                    error={!!error}
                                    required
                                    helperText={error?.message}
                                    options={species}
                                    optionsValue="uuid"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} lg={3} xs={12}>
                        <Controller
                            name="subSpeciesUuid"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    onChange={onChange}
                                    options={subSpecies.filter((subSpecies) => subSpecies.parentUuid === speciesUuid)}
                                    optionsValue="uuid"
                                    disabled={!hasSubSpecies}
                                    required={hasSubSpecies}
                                    error={!!error}
                                    helperText={error?.message}
                                    label="Sous-catégorie d'espèces"
                                    value={value || ""}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} lg={3} xs={12}>
                        <Controller
                            name="sector"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value || ""}
                                    label="Filière"
                                    onChange={onChange}
                                    error={!!error}
                                    required
                                    helperText={error?.message}
                                    options={sectors}
                                    optionsValue="key"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={6} lg={3} xs={12}>
                        <Controller
                            name="tier"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value || ""}
                                    label="Etage"
                                    onChange={onChange}
                                    error={!!error}
                                    required
                                    helperText={error?.message}
                                    options={tiers}
                                    optionsValue="key"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <form
            onSubmit={handleSubmit((data) => {
                setForm({ ...form, animalInformation: data as IVaccinationInterventionAnimalInformation });
                onValid();
            })}
            noValidate
        >
            <Stack spacing={2}>
                <Typography variant="h5">Vaccin</Typography>
                {renderVaccineInformation()}
                <Typography variant="h5">Animaux vaccinés</Typography>
                {renderAnimalInformation()}
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
