import { ArrayOfTwo } from "@/interfaces/global";
import { IHealthAccreditationTraining, ITrainer, SessionMode } from "@/interfaces/training";

export interface IHealthAccreditationCreate
    extends Omit<IHealthAccreditationTraining, "uuid" | "attachedFiles" | "type"> {
    type: string;
}

export interface ICatalogHealthAccreditationTrainingFilters {
    title: string;
    typeUuid: string;
    targetPublic: string;
    duration: ArrayOfTwo;
    ectsPoints: string;
    inscriptionNumberMin: string;
    inscriptionNumberMax: string;
    archived: string;
}

export interface ITrainingSessionCreate {
    trainingUuid: string;
    startDate: string;
    inscriptionNumberMin: number;
    inscriptionNumberMax: number;
    comment: string;
    ddppUuid: string;
    trainingSessionMode: keyof typeof SessionMode;
    address: string;
    complementaryAddress: string;
    postalCode: string;
    city: string;
    trainers: ITrainer[];
}

export enum TrainerType {
    VETERINARY = "Vétérinaire",
    MINISTRY_AGENT = "Agent du ministère de l'agriculture",
}
