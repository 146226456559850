// === NPM
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
// === LOCAL
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import ChipTag from "@/components/generics/ChipTag";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { ITag } from "@/interfaces/faq";
import { HttpStatus } from "@/interfaces/global";
import FaqService from "@/services/FaqService";
import { FaqOutletContext } from "../../../interface";
import TagDialog from "./TagDialog";

export default function Tag() {
    const { tags, getTags, getQuestions } = useOutletContext<FaqOutletContext>();
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedTagUuid, setSelectedTagUuid] = useState<string>("");
    const selectedTag = tags.find((tag) => tag.uuid === selectedTagUuid);

    const handleCreateTag = async (tag: ITag) => {
        const res = await FaqService.postTag(tag);
        if (res.status === HttpStatus.CREATED) {
            await getTags();
            setOpenCreateDialog(false);
            toast.success("Tag créé avec succès");
        }
    };

    const handleUpdateTag = async (tag: ITag) => {
        const res = await FaqService.putTag({ ...tag, uuid: selectedTagUuid });
        if (res.status === HttpStatus.OK) {
            await getTags();
            setOpenUpdateDialog(false);
            setSelectedTagUuid("");
            toast.success("Tag modifié avec succès");
        }
    };

    const handleDeleteTag = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            return;
        }
        const res = await FaqService.deleteTag(selectedTagUuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            await getTags();
            setOpenDeleteDialog(false);
            toast.success("Tag supprimé avec succès");
            getQuestions();
        }
    };

    const renderTag = (tag: ITag, index: number) => (
        <React.Fragment key={tag.uuid}>
            <Grid item xs={12} lg={6.2} justifyContent="space-between" display="flex" alignItems="center">
                <ChipTag tag={tag} />
                <Box>
                    <EditAction
                        title="Modifier le tag"
                        onClick={() => {
                            setSelectedTagUuid(tag.uuid);
                            setOpenUpdateDialog(true);
                        }}
                    />
                    <DeleteAction
                        title="Supprimer le tag"
                        onClick={() => {
                            setSelectedTagUuid(tag.uuid);
                            setOpenDeleteDialog(true);
                        }}
                    />
                </Box>
            </Grid>
            {index % 2 === 0 && (
                <Grid item lg={0.1} mx={1} display={{ xs: "none", lg: "inherit" }}>
                    <Divider orientation="vertical" />
                </Grid>
            )}
        </React.Fragment>
    );

    const renderTags = () => {
        return tags.length > 0 ? (
            <Card sx={{ p: 2 }}>
                <StyledCardContent>
                    <Grid container columns={13.1} columnSpacing={1}>
                        {tags.map((tag, index) => renderTag(tag, index))}
                    </Grid>
                </StyledCardContent>
            </Card>
        ) : (
            <Typography variant="h6" align="center">
                Aucun tag
            </Typography>
        );
    };

    return (
        <>
            <Stack height="100%" spacing={2} width="100%">
                <Box justifyContent="space-between" display="flex">
                    <Typography variant="h4">Gestion des tags</Typography>
                    <GenericButton
                        label="Ajouter un tag"
                        startIcon={<Add />}
                        onClick={() => {
                            setOpenCreateDialog(true);
                        }}
                    />
                </Box>
                {renderTags()}
            </Stack>
            {openCreateDialog && (
                <TagDialog
                    onClose={() => setOpenCreateDialog(false)}
                    onValid={handleCreateTag}
                    title={"Création d'un tag"}
                />
            )}
            {openUpdateDialog && (
                <TagDialog
                    onClose={() => setOpenUpdateDialog(false)}
                    onValid={handleUpdateTag}
                    title={"Modification d'un tag"}
                    currentTag={selectedTag}
                />
            )}
            {openDeleteDialog && (
                <GenericConfirmDialog
                    title="Suppression d'un tag"
                    message={`Êtes-vous sûr de vouloir supprimer le tag ${selectedTag?.name} ?`}
                    onClose={handleDeleteTag}
                />
            )}
        </>
    );
}
